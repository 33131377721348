import { APPLICATION_ENUM, AUTH_PROVIDERS } from '@ttg/app-interfaces-lib';
import config from '../constants/config';

export const useSSOParams = {
	linkedinClientId: config.linkedinClientId,
	linkedinRedirectUri: `${config.base_url}/client/login/${AUTH_PROVIDERS.LINKEDIN}`,
	allowedAuthProviders: config.allowedAuthProviders,
	googleClientId: config.googleClientId,
	microsoftClientId: config.microsoftClientId,
	microsoftRedirectUri: `${config.base_url}/client/login/${AUTH_PROVIDERS.MICROSOFT}`,
	loginPath: '/client/login/',
	privacyUrl: `${config.base_url}/policy/privacy-policy`,
	termsOfServiceUrl: `${config.base_url}/policy/terms-of-service`,
	appId: APPLICATION_ENUM.WG,
	apiEndpoint: config.ttg_url,
	redirectPaths: [`/client/login/${AUTH_PROVIDERS.LINKEDIN}`, `/client/login/${AUTH_PROVIDERS.MICROSOFT}`]
};
