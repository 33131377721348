import React from 'react';
import AppIcon from '../AppIcon';
import toast from 'react-hot-toast';

const ToasterMessage = ({ toaster, title, message, actions, onCloseAction }) => {
	const [loading, setLoading] = React.useState(false);

	return (
		<div>
			<div className={'flex flex-row'}>
				<p
					className={'font-medium wg-toaster-title'}
					style={{ fontSize: '16px', minWidth: '280px', maxWidth: '280px', lineHeight: '22px' }}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<AppIcon
					id={'wg-toaster-close-button'}
					icon={'cancel'}
					className={'text-gray-400 cursor-pointer'}
					onClick={() => {
						toast.dismiss(toaster.id);
						onCloseAction && onCloseAction();
					}}
				/>
			</div>
			<p
				className={'text-gray-500 ttg-toaster-message'}
				style={{ fontSize: '14px', minWidth: '280px', maxWidth: '280px', lineHeight: '20px' }}
				dangerouslySetInnerHTML={{ __html: message }}
			/>
			{actions && actions.length > 0 && (
				<div
					id={'wg-toaster-message-actions'}
					className={`mt-3 ${loading ? 'opacity-50' : ''}`}
					style={{ fontSize: '14px', minWidth: '280px', maxWidth: '280px', lineHeight: '20px' }}
				>
					{actions.map(({ text, action }, key) => (
						<p
							key={key}
							className={`text-secondary font-semibold ${
								loading ? 'cursor-not-allowed' : 'cursor-pointer'
							}`}
							onClick={() => {
								setLoading(true);
								if (!loading) {
									action().then(() => {
										setLoading(false);
										toast.dismiss(toaster.id);
									});
								}
							}}
						>
							{text}
						</p>
					))}
				</div>
			)}
		</div>
	);
};

export default ToasterMessage;
