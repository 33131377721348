import React from 'react'
import { Helmet } from 'react-helmet';
import { getCertificationMetaData } from './StructuredMetaDataUtility';

export const CertificationStructuredMetaData = ({ product, events}) => {
	const structuredData = getCertificationMetaData(product, events)

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(structuredData)}
			</script>
		</Helmet>
	);
}