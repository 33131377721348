import React from 'react';
import toast from 'react-hot-toast';
import ToasterIcon from '../components/toasters/ToasterIcon';
import ToasterMessage from '../components/toasters/ToasterMessage';
import moment from 'moment';
import UtilityService from './UtilityService';
import ApiService from './ApiService';

const success = (title, message, options = {}, actions = []) => {
	toast((t) => <ToasterMessage toaster={t} title={title} message={message} actions={actions} />, {
		icon: <ToasterIcon type={'success'} />,
		...options,
	});
};

const error = (title, message, options = {}, actions = []) => {
	toast((t) => <ToasterMessage toaster={t} title={title} message={message} actions={actions} />, {
		icon: <ToasterIcon type={'error'} />,
		...options,
	});
};

const info = (title, message, options = {}, actions = [], icon, onCloseAction) => {
	toast((t) => <ToasterMessage toaster={t} title={title} message={message} actions={actions} onCloseAction={onCloseAction}/>, {
		icon: <ToasterIcon type={'info'} customIcon={icon} />,
		...options,
	});
};

const get = (params) => {
	return new Promise((resolve, reject) => {
		const authCookie = UtilityService.getAuthCookie();
		if (!authCookie) {
			return reject({ message: 'Not logged in' });
		}

		const queryString = UtilityService.serializeQueryString(params);
		ApiService.get(`v1/notifications?${queryString}`)
				.then(({ data }) => {
					resolve(groupNotifications(data.notifications));
				})
				.catch((error) => {
					reject(error);
				});

	});
};

const toggleMarkAsRead = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/notification/mark_as_read`, {
			notification_id: payload.id,
			read: payload.read,
		})
			.then(resolve)
			.catch(reject);
	});
};

const markAllAsRead = () => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/notification/mark_all_as_read`, {
			read: true,
		}).then(resolve, reject);
	});
};

const groupNotifications = (notifications) => {
	const today = notifications.filter(
		(notification) =>
			notification.type === 'local' && moment(notification.notification_date).isSame(moment(), 'day')
	);

	const later = notifications.filter(
		(notification) =>
			notification.type === 'local' && moment(notification.notification_date).isBefore(moment(), 'day')
	);

	const wg = notifications.filter((notification) => notification.type === 'global');

	const unreadLocal = notifications.filter((n) => n.type === 'local' && !n.read).length;
	const unreadGlobal = notifications.filter((n) => n.type === 'global' && !n.read).length;
	const unreadAll = notifications.filter((n) => !n.read).length;

	return { today, later, wg, unreadLocal, unreadGlobal, unreadAll };
};

const setNotificationsAsRead = (notifications) => {
	return notifications.map((notification) => {
		return { ...notification, read: true };
	});
};

const NotificationService = {
	success,
	error,
	info,
	get,
	toggleMarkAsRead,
	markAllAsRead,
	groupNotifications,
	setNotificationsAsRead,
};

export default NotificationService;
