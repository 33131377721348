import React from 'react';
import { useForm } from 'react-hook-form';

import AppInput from '../AppInput';
import AppButton from '../AppButton';
import { passwordRegex } from '../../services/UtilityService';
import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import { useUser } from "../../hooks/UserProvider";
import { AUTH_PROVIDERS } from '@ttg/app-interfaces-lib';

const ChangePassword = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		reset,
	} = useForm();
	const { user } = useUser();
	const [loading, setLoading] = React.useState(false);

	const onSubmit = (data) => {
		const password_update_data = {
			new_password: data.new_password,
			old_password: data.current_password,
		};
		save(password_update_data);
	};

	const save = (payload) => {
		setLoading(true);
		CustomerService.updatePassword(payload)
			.then(() => {
				setLoading(false);
				NotificationService.success('Password updated successfully!');
				reset();
				setLoading(false);
			})
			.catch((result) => {
				setLoading(false);
				NotificationService.error('Notice', result.message);
			});
	};

	return (
		<div>
			<p>Need to Change Your Password?</p>
			<form className={'w-full'} onSubmit={handleSubmit(onSubmit)}>
				<AppInput
					disabled={user.customers_login_method !== AUTH_PROVIDERS.PASSWORD}
					label={'Current password *'}
					type={'password'}
					placeholder={'Please enter your current password'}
					errors={errors}
					{...register('current_password', {
						required: true,
					})}

				/>

				<AppInput
					disabled={user.customers_login_method !== AUTH_PROVIDERS.PASSWORD}
					label={'New password *'}
					type={'password'}
					placeholder={'Please enter a new password'}
					errors={errors}
					{...register('new_password', {
						required: true,
						pattern: {
							value: passwordRegex,
							message:
								'Password must contain at least 8 characters, 1 upper case character, 1 lowercase character and 1 number.',
						},
					})}
				/>

				<AppInput
					disabled={user.customers_login_method !== AUTH_PROVIDERS.PASSWORD}
					label={'Confirm password *'}
					type={'password'}
					placeholder={'Please confirm your new password'}
					errors={errors}
					{...register('confirm_password', {
						required: true,
						validate: (value) => value === getValues('new_password') || 'Passwords do not match',
					})}
				/>

				<AppButton
					disabled={user.customers_login_method !== AUTH_PROVIDERS.PASSWORD}
					type='submit'
					className={'btn btn-primary mb-4'}
					text={'Save Changes'}
					loading={loading}
					id={'wg-account-change-pass-btn'}
				/>
			</form>
		</div>
	);
};

export default ChangePassword;
