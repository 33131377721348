import React from 'react';
import AppButton from '../../AppButton';
import AppRIIcon from '../../AppRIIcon';

const EventItem = ({ event, onRegister, onRegisterAsAdmin, isAdmin }) => {
	const hasDiscount = !!event.price_sale;
	const hasSpots = event.remaining_spots > 0;

	return (
		<div className={'event-item-box'}>
			<div className={'flex flex-col flex-1 p-4'}>
				<div className='flex flex-1 flex-col'>
					{event.is_weekend ? (
						<p className={'newly-added-tag'}>SPECIAL WEEKEND SESSION!</p>
					) : (
						event.new_session && <p className={'newly-added-tag'}>NEWLY ADDED!</p>
					)}
					<div className='event-info items-start'>
						<div className='event-item-icon'>
							<AppRIIcon icon={'calendar-event-fill'} className={'text-2xl text-primary'} />
						</div>
						<div>
							<p className={'text-primary font-semibold text-xl'}>{event.start_date}</p>
							<p className={'text-primary font-semibold text-xl'}>{event.end_date}</p>
						</div>
					</div>
					<div className='event-info items-center'>
						<div className='event-item-icon'>
							<AppRIIcon icon={'time-line'} className={'text-2xl text-primary'} />
						</div>
						<div>
							<p className={'text-primary font-semibold text-xl'}>{event.event_time}</p>
						</div>
					</div>
					<div className='event-info items-center'>
						<div className='event-item-icon'>
							<AppRIIcon icon={'map-pin-line'} className={'text-2xl text-primary'} />
						</div>
						<div>
							<p className={'text-primary font-semibold text-xl'}>{event.location || 'Virtual'}</p>
						</div>
					</div>
					<p className='text-base text-gray-600 leading-6 font-medium tracking-wide uppercase'>
						{hasSpots
							? `Only ${event.remaining_spots} ${event.remaining_spots > 1 ? 'spots' : 'spot'} available`
							: 'Sold out'}
					</p>
					<hr className={'bg-primary h-1 my-4'} />
				</div>
				<div className={'flex flex-row items-center justify-between'}>
					<div>
						<span className='text-base font-normal text-gray-600 block'>Price: </span>
						<span
							className={`${
								hasDiscount
									? 'line-through text-gray-800 text-base font-medium'
									: 'text-xl text-gray-900 font-semibold'
							}`}
						>
							{event.price}
						</span>
						{hasDiscount && (
							<span className={'text-xl text-gray-900 font-semibold'}> {event.price_sale}</span>
						)}
						{!!event.price_discount_formatted && (
							<span className={'font-thin text-secondary block text-12'}>
								{event.price_discount_formatted}
							</span>
						)}
					</div>
					<AppButton
						className={'btn btn-dark w-44'}
						text={hasSpots ? 'Register now' : 'Sold out'}
						disabled={!hasSpots}
						onClick={() => isAdmin ? onRegisterAsAdmin() : hasSpots && onRegister(event)}
						id={'wg-event-register-btn'}
					/>
				</div>
			</div>
		</div>
	);
};

export default EventItem;
