export const getProductStructuredData = ({ name, fullName, defaultPrice, images, metadata, url, id, description }) => {
	const { sku, google_product_category, virtual } = metadata;
	const image = images[0];
	const price = (defaultPrice.unit_amount / 100).toFixed(2);

	return {
		'@context': 'https://schema.org',
		'@type': 'Product',
		name: fullName ?? name,
		image,
		description,
		sku,
		productID: id,
		brand: {
			'@type': 'Brand',
			name: 'The Table Group',
		},
		offers: {
			'@type': 'Offer',
			url,
			priceCurrency: 'USD',
			price,
			itemCondition: 'https://schema.org/NewCondition',
			availability: virtual && virtual === 'true' ? 'https://schema.org/OnlineOnly' : 'https://schema.org/InStock',
			seller: {
				'@type': 'Organization',
				name: 'The Table Group',
			},
		},
		google_product_category,
	};
};

export const getStoreStructuredData = (products) => {
	return products.map((product, index) => {
		const item = getProductStructuredData(product);

		return {
			"@type": "ListItem",
			"position": index,
			item,
		};
	});
};

export const getCertificationMetaData = (data, events) => {
	let structuredData = getProductStructuredData(data)

	return {
		...structuredData,
		"event": events.map((event) => {
			return {
				"@type": "Event",
				"name": event.name,
				"startDate": event.start_date,
				"endDate": event.end_date,
				"remainingAttendeeCapacity": event.remaining_spots,
				"doorTime": event.event_time,
				"offers": {
					"@type": "Offer",
					"price": event.defaultPrice,
					"priceCurrency": "USD",
					"availability": "https://schema.org/OnlineOnly"
				}
			};
		})
	}

};