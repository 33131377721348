import React, { useEffect } from 'react';
import AppSlideOver from '../AppSlideOver';
import { clipboard } from '../../images';
import { formatDate } from '../../services/UtilityService';
import AppButton from '../AppButton';
import NotificationService from '../../services/NotificationService';
import AssessmentsService from '../../services/AssessmentsService';
import AppAutocomplete from '../AppAutocomplete';
import DuplicatedInAssessment from './DuplicatedInAssessment';
import debounce from 'debounce-promise';
import AssessmentAutocompleteOption from './AssessmentAutocompleteOption';
import AppLabel from '../AppLabel';
import { navigate } from 'gatsby';
import AppSwitch from '../AppSwitch';
import WgIcon from '../WgIcon';
import BulkDuplicateDetails from './BulkDuplicateDetails';

const MoveAssessment = ({
	isOpen,
	onClose,
	item,
	isDuplicate = false,
	isMovingAll,
	isGroupAssessment,
	totalSelected,
	redirectOnDelete = false,
}) => {
	const [selectedGroup, setSelectedGroup] = React.useState({});
	const [alreadyDuplicatedAssessments, setAlreadyDuplicatedAssessments] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [movingOutOfGroup, setMovingOutOfGroup] = React.useState(false);

	const selectRef = React.useRef();
	const getGroups = async (search) => {
		let type = '';
		if (item.roster_ids && item.roster_ids.length > 1) {
			type = 'group';
		}
		return AssessmentsService.getGroupsForMove({ search, type });
	};

	useEffect(() => {
		if (!isOpen) {
			setLoading(false);
			setSelectedGroup({});
			setMovingOutOfGroup(false);
		}
	}, [isOpen]);

	const move = () => {
		setLoading(true);
		const payload = {
			from_assessment_id: item.id,
		};
		if (selectedGroup.id) {
			payload.to_assessment_id = selectedGroup.id;
		} else {
			payload.group = selectedGroup.value;
		}
		if (item.roster_id) {
			payload.roster_ids = [item.roster_id];
		} else if (item.roster_ids) {
			payload.roster_ids = item.roster_ids;
		}
		if (movingOutOfGroup) {
			payload.first_name = item.fname;
			payload.last_name = item.lname;
		}
		AssessmentsService.move(payload)
			.then((response) => {
				setLoading(false);
				const isGroup = selectedGroup.type === 'group' || selectedGroup.__isNew__;
				const movedTo = selectedGroup.group
					? selectedGroup.group
					: selectedGroup.__isNew__
					? selectedGroup.value
					: movingOutOfGroup
					? `${item.fname} ${item.lname}`
					: `${selectedGroup.fname} ${selectedGroup.lname}`;
				NotificationService.success(
					`Assessment Successfully Moved to <b>${movedTo}</b> ${isGroup ? 'group' : 'individual'}.`
				);
				if (response.deleted && redirectOnDelete) {
					onClose();
					navigate(`/client/assessment/?id=${response.to_assessment_id}`, { replace: true });
				} else {
					onClose({ reload: true });
				}
			})
			.catch((e) => {
				setLoading(false);
				NotificationService.error('Oops', e.message, { duration: 6000 });
				onClose();
			});
	};

	const duplicate = () => {
		setLoading(true);
		if (item?.roster_ids?.length > 1 && selectedGroup.id && !alreadyDuplicatedAssessments) {
			return getAlreadyDuplicatedAssessments();
		}

		const payload = {
			from_assessment_id: item.id,
			roster_ids: item.roster_ids ?? [item.roster_id],
		};
		if (selectedGroup.id) {
			payload.to_assessment_id = selectedGroup.id;
		} else {
			payload.group = selectedGroup.value;
		}
		AssessmentsService.duplicate(payload)
			.then(() => {
				setLoading(false);
				setAlreadyDuplicatedAssessments(null);
				const movedTo = selectedGroup.group ? selectedGroup.group : selectedGroup.value;
				NotificationService.success(
					`Assessment${
						item?.roster_ids?.length > 1 ? 's' : ''
					} Successfully Duplicated to <b>${movedTo}</b> group.`
				);
				onClose({ reload: true });
			})
			.catch((e) => {
				setLoading(false);
				setAlreadyDuplicatedAssessments(null);
				NotificationService.error('Oops', e.message, { duration: 6000 });
				onClose();
			});
	};

	const getAlreadyDuplicatedAssessments = () =>
		AssessmentsService.getAlreadyDuplicatedAssessments({
			roster_ids: item.roster_ids,
			to_assessment_id: selectedGroup.id,
		})
			.then(({ data }) => {
				setAlreadyDuplicatedAssessments(data);
				setLoading(false);
			})
			.catch(() => {
				setAlreadyDuplicatedAssessments(null);
				setLoading(false);
			});

	const renderTitle = () => {
		if (isDuplicate) {
			return item?.roster_ids?.length > 1 ? 'Duplicate Assessments' : 'Duplicate Assessment';
		}
		return item.roster_ids ? 'Move Assessments' : 'Move Assessment';
	};

	return (
		<AppSlideOver id={'move-assessment-slide-over'} isOpen={isOpen} closeModal={onClose}>
			<div className='share-assessment-slide-over'>
				<div className='share-assessment-info'>
					<p className='share-assessment-heading'>{renderTitle()}</p>
					<p className='share-assessment-text'>
						{isDuplicate
							? 'Select the group you would like to move the duplicated assessment to and click the “Duplicate” button.'
							: 'To move this assessment into a Group, please enter the Group Name or select Create a New Group. Once you click “Move Assessment” it will be relocated in your console into that Group.'}
					</p>
					{!!isMovingAll && (
						<p className='text-14 font-bold leading-5 mb-4 text-primary'>
							Are you sure you want to move all the assessments?
							<br />
							<br />
							This group will be recreated and the incomplete report will be deleted.
						</p>
					)}
				</div>

				<div className='assessment-info flex flex-col items-center'>
					<img className={'object-contain mb-4'} width='40' src={clipboard} alt='' />
					{item.fname && item.lname && (
						<p className='share-assessment-heading text-base'>{`${item.fname} ${item.lname}`}</p>
					)}
					{item.company && <p className='share-assessment-text'>{item.company}</p>}
					<p className='share-assessment-text'>
						{!item.roster_ids || totalSelected === 1
							? 'Individual Assessment'
							: 'Multiple Individual Assessments'}
					</p>
					{(!item.roster_ids || totalSelected === 1) && (
						<p className='share-assessment-text'>Completed at {formatDate(item.completed)}</p>
					)}
				</div>
				<hr className='slide-over-break' />

				{
					isDuplicate &&
					(item?.roster_ids?.length === 1 || item.roster_id ) &&
					<DuplicatedInAssessment roster_id={
						item?.roster_ids?.length > 0 ?
							item?.roster_ids[0] :
							item.roster_id
					}/>
				}

				<AppLabel
					label={isDuplicate ? 'Search or Create Groups' : 'Search or Create Groups/Individuals'}
					labelTooltip={`${
						isDuplicate
							? 'You can choose between existing group assessments, or create a new group to duplicate selected assessment into this group.'
							: 'You can choose between existing groups and individual assessments, or create a new group to move selected assessment into this group.'
					} This action will not use any of your licenses.`}
					labelIcon={'info-circled'}
				/>
				<div style={{ height: '5px' }} />
				<AppAutocomplete
					innerRef={selectRef}
					placeholder={'Search or Create...'}
					creatable={true}
					allowCreateWhileLoading={false}
					option={AssessmentAutocompleteOption}
					getOptionValue={(option) => option.id}
					getOptionLabel={(option) => (
						<div className={'flex flex-row items-center'}>
							<WgIcon item={option} className={'text-2xl mr-2'} />
							<p>
								{option.type === 'individual'
									? `${option.fname} ${option.lname}`
									: option.group
									? option.group
									: option.value}
							</p>
						</div>
					)}
					isOptionDisabled={(option) =>
						!item.roster_ids &&
						option.type === 'individual' &&
						(item.assessment_mode
							? item.assessment_mode !== option.assessment_mode
							: item.roster_mode !== option.assessment_mode)
					}
					filterOption={(option) => {
						if (isDuplicate) return !(option.data.type === 'individual');
						return !(option.data.type === 'individual' && option.data.complete === 1);
					}}
					loadOptions={debounce(getGroups, 500)}
					isClearable={true}
					cacheOptions={false}
					noOptionsMessage={() => 'Start typing to search...'}
					onChange={(i) => {
						setSelectedGroup(i ? i : {});
					}}
					isDisabled={movingOutOfGroup}
				/>
				<div style={{ height: '10px' }} />
				<hr className='slide-over-break' />

				{isGroupAssessment &&
					!isDuplicate &&
					(item.roster_id || totalSelected === 1 || (item.roster_ids && item.roster_ids.length === 1)) && (
						<>
							<div id={'wg-move-out-of-group-toggle'} className={'flex flex-1 flex-row items-center'}>
								<AppSwitch
									value={movingOutOfGroup}
									onChange={(value) => {
										if (value) {
											selectRef.current.clearValue();
											setSelectedGroup({});
										}
										setMovingOutOfGroup(value);
									}}
								/>
								<p className='ml-4 text-14'>
									Please select if you would like this assessment removed from the current Group and
									listed as an Individual Assessment in your console.
								</p>
							</div>

							<hr className='slide-over-break' />
						</>
					)}

				{!!item.selectedData && (
					<BulkDuplicateDetails
						alreadyDuplicatedAssessments={alreadyDuplicatedAssessments}
						rosters={item.selectedData}
						intro={`You are duplicating the selected reports to <b>${selectedGroup.group}</b> group.`}
						onClose={() => setAlreadyDuplicatedAssessments(null)}
						onDuplicate={duplicate}
						loading={loading}
					/>
				)}

				<div className='flex mt-5'>
					<AppButton
						disabled={!selectedGroup.id && !selectedGroup.__isNew__ && !movingOutOfGroup}
						onClick={isDuplicate ? duplicate : move}
						loading={loading}
						text={isDuplicate? 'Duplicate' : 'Move assessment'}
						className={'btn btn-primary btn-share-assessment w-full mr-2'}
						id={'wg-move-assessment-with-share-btn'}
					/>
					<AppButton
						type={'button'}
						className={'btn btn-secondary'}
						onClick={onClose}
						loading={loading}
						text={'Cancel'}
						id={'wg-move-assessment-with-cancel-btn'}
					/>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default MoveAssessment;
