import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import AppInput from '../../AppInput';
import AppTextarea from '../../AppTextarea';
import AppButton from '../../AppButton';
import AppLabel from '../../AppLabel';
import AppCheckBox from '../../AppCheckBox';
import {
	create,
	getCompanyLogoSignedUrl,
	uploadCompanyLogo,
	updateCompanyLogo,
} from '../../../services/AssessmentsService';
import UtilityService from '../../../services/UtilityService';
import AppErrorMessage from '../../AppErrorMessage';
import AppSwitch from '../../AppSwitch';
import AppFileUploader from '../../AppFileUploader';
import AppRadioGroup from '../../AppRadioGroup';
import CompanyLogoPreviewModal from '../CompanyLogoPreviewModal';
import config from '../../../constants/config';
import MultipleEmailInput from '../MultipleEmailInput';
import { texts } from './constants';
import { AssessmentModeEnum } from '../../../constants/AssessmentsData';
import { useUser } from '../../../hooks/UserProvider';
import { useAssessments } from '../../../hooks/AssessmentProvider';

const AssessmentInformation = ({ previousStep, nextStep, mode, setShareReport }) => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [previewCompanyLogo, setPreviewCompanyLogo] = React.useState(false);
	const [companyLogo, setCompanyLogo] = React.useState(null);
	const [addRecipient, setAddRecipient] = React.useState(0);
	const { user, loadUser } = useUser();
	const certified = user.wg_certification_status?.status_id > 0;
	const agree = user.agree > 0;
	const { setAssessment, assessmentType } = useAssessments();
	const isGroup = assessmentType === 'group';
	const multipleRef = React.useRef();

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		isSubmitted,
		getValues,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			welcome: texts[mode].welcome + user.fullName,
			licenses: isGroup ? 2 : 1,
			send: false,
			notify: false,
			multi_language: mode === AssessmentModeEnum.STANDARD,
			cc_emails: [],
		},
	});

	useEffect(() => {
		if (!isValidSelectedType()) {
			previousStep();
		}
	}, []);

	React.useEffect(() => {
		if (!getValues('notify')) {
			setValue('cc_emails', []);
		}
	}, [getValues('notify')]);

	const isValidSelectedType = () => {
		return ['group', 'individual'].indexOf(assessmentType) > -1;
	};

	const save = (payload) => {
		setErrorMessage('');
		setLoading(true);
		create({ ...payload, type: assessmentType, assessment_mode: mode })
			.then(async (assessment) => {
				setAssessment({
					...assessment,
					type: assessmentType,
					assessment_mode: mode,
					licenses_removed: isGroup ? payload.licenses : 1,
				});
				return assessment.assessment_id;
			})
			.then((id) => {
				if (certified && companyLogo) {
					return getCompanyLogoSignedUrl(id, companyLogo.name)
						.then(({ key, url }) => {
							return uploadCompanyLogo(key, url, companyLogo);
						})
						.then(({ key }) => {
							updateCompanyLogo(id, { company_logo: key });
						});
				}
			})
			.then(() => {
				nextStep();
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
				setCompanyLogo(null);
				loadUser();
			});
	};

	if (!isValidSelectedType()) {
		return null;
	}

	return (
		<div className={`container px-8 md:mx-auto mt-8 ${config.name}`}>
			<h1 className='font-bold text-5xl text-primary text-center'>{texts[mode][assessmentType].title}</h1>
			<form onSubmit={handleSubmit(save)} noValidate={true}>
				{!isGroup && (
					<>
						<div className='flex flex-1 flex-col md:flex-row'>
							<div className='flex-1 md:mr-2'>
								<AppInput
									{...register('fname', {
										required: true,
									})}
									errors={errors}
									placeholder={'Please enter the first name'}
									label={'First Name *'}
								/>
							</div>
							<div className='flex-1 md:ml-2'>
								<AppInput
									{...register('lname', {
										required: true,
									})}
									errors={errors}
									placeholder={'Please enter the last name'}
									label={'Last Name *'}
								/>
							</div>
						</div>

						<div className='flex flex-1 flex-col md:flex-row'>
							<div className='flex-1'>
								<AppInput
									{...register('company')}
									errors={errors}
									placeholder={`${texts[mode][assessmentType].organizationPrompt}`}
									label={`${texts[mode][assessmentType].organization} Name`}
								/>
							</div>
						</div>
					</>
				)}
				{isGroup && (
					<div className='flex flex-1 flex-col md:flex-row'>
						<div className='flex-1 md:mr-2'>
							<AppInput
								{...register('group', {
									required: 'Please enter a group name',
								})}
								errors={errors}
								placeholder={'Please enter the group name'}
								label={'Group Name *'}
							/>
						</div>
						<div className='flex-1 md:ml-2'>
							<AppInput
								{...register('company', {
									required:
										mode === AssessmentModeEnum.STANDARD &&
										assessmentType === 'group' &&
										`${texts[mode][assessmentType].organizationPrompt}`,
								})}
								errors={errors}
								placeholder={`${texts[mode][assessmentType].organizationPrompt}`}
								label={`${texts[mode][assessmentType].organization} Name ${mode === AssessmentModeEnum.STANDARD && assessmentType === 'group' ? '*' : ''
									}`}
							/>
						</div>
					</div>
				)}
				{isGroup && (
					<div className='flex flex-1'>
						<AppInput
							{...register('licenses', {
								required: true,
								min: 2,
								max: user.licenses,
							})}
							min={2}
							max={user.licenses}
							data-wg-min={2}
							data-wg-max={user.licenses}
							containerClassName={'w-full'}
							type={'number'}
							errors={errors}
							label={'How many licenses do you want to assign to this group? *'}
							message={() => {
								return (
									<span className='text-gray-500'>
										You have <span className='font-bold'>{user.licenses}</span> licenses available
									</span>
								);
							}}
						/>
					</div>
				)}
				<div className='flex flex-1'>
					<AppTextarea
						containerClassName={'w-full'}
						{...register('welcome', {
							required: true,
						})}
						errors={errors}
						label={'Welcome message'}
						labelTooltip={'This message will display on the initial assessment page'}
					/>
				</div>
				{certified && (
					<div className='flex flex-1 flex-col items-start'>
						<AppLabel
							label={'Company Logo'}
							labelTooltip={`The company logo will appear on the PDF report of each team member taking this assessment. Upon uploading your company logo click "Preview" under the upload box in order to view the logo on the assessment report before finishing assessment set up.`}
						/>
						<AppFileUploader
							onDone={(file) => setCompanyLogo(file)}
							onRemove={() => setCompanyLogo(null)}
						/>
						{companyLogo ? (
							<div className='flex flex-col'>
								<CompanyLogoPreviewModal
									isOpen={previewCompanyLogo}
									closeModal={() => setPreviewCompanyLogo(false)}
									srcFile={companyLogo}
								/>
								<span
									className='text-primary text-sm font-semibold cursor-pointer p-1 mt-2 hover:bg-blue-100'
									style={{
										width: 'max-content',
									}}
									onClick={() => setPreviewCompanyLogo(true)}
								>
									Preview
								</span>
							</div>
						) : null}
					</div>
				)}

				<div>
					{isGroup && <p className={'text-primary font-bold my-4'}>Options for group/team setup</p>}
					<div className='flex flex-row mt-2' id={'wg-create-assessment-no-automatic-reports-container'}>
						<AppSwitch
							errors={errors}
							value={watch('send')}
							onChange={(val) => {
								setValue('send', val, {
									shouldDirty: true,
								});
								setShareReport(val);
							}}
							id={'wg-account-language-selection'}
						/>
						<div className='ml-2'>
							<p className={'text-primary font-semibold'}>
								Do not automatically send reports to participants. Completed reports can be downloaded
								in your console.{' '}
							</p>
							<p className={'text-primary font-medium italic'}>
								This option is most commonly used by consultants or facilitators running a group
								session.
							</p>
						</div>
					</div>
					<div className='flex flex-col mt-2' id={'wg-create-assessment-notify-on-completion-container'}>
						<AppSwitch
							label={
								<p className='text-primary font-semibold'>
									Notify me as participants complete the assessment
								</p>
							}
							errors={errors}
							value={watch('notify')}
							onChange={(val) => {
								setValue('notify', val, {
									shouldDirty: true,
								});
							}}
							id={'wg-create-assessment-notify-on-completion'}
						/>
						{getValues('notify') && (
							<>
								<p className='text-xs leading-4 font-normal text-gray-600 mt-5'>
									As each team member completes the assessment, an email will be sent to the main
									contact and any specified additional contacts. This e-mail will provide a summary of
									which team members have completed the assessment. Enter up to 3 email address of the
									people who should receive status updates.
								</p>
								{user?.original_customer && (
									<>
										<p className={'text-sm text-gray-800 font-medium leading-5 mt-4 mb-2'}>
											The default recipient of these notifications is the account owner. Do you
											want to add your email in order to receive notifications?{' '}
										</p>
										<AppRadioGroup
											name={'add_recipient'}
											options={[
												{
													label: 'Yes',
													value: 1,
												},
												{
													label: 'No',
													value: 0,
												},
											]}
											value={addRecipient}
											onChange={(val) => {
												let mainEmail = user.original_customer.customers_email_address;
												setAddRecipient(val);
												const members = getValues('cc_emails');
												const idx = members.findIndex(({ value }) => value === mainEmail);
												if (val) {
													if (idx === -1) {
														const emptyIndex = members.findIndex(({ value }) => !value);
														if (emptyIndex > -1) {
															members[emptyIndex].label =
																user?.original_customer.customers_email_address;
															members[emptyIndex].value =
																user?.original_customer.customers_email_address;
															members[emptyIndex].isValidEmail =
																UtilityService.isValidEmail(mainEmail);
														} else {
															members.push({
																label: mainEmail,
																value: mainEmail,
																isValidEmail: UtilityService.isValidEmail(mainEmail),
																fakeId: UtilityService.generateRandomString(30),
															});
														}
													}
												} else {
													if (idx > -1) {
														members.splice(idx, 1);
													}
												}
												setValue('cc_emails', members);
											}}
										/>
									</>
								)}
								<div className='short-form'>
									<MultipleEmailInput
										name={'emails'}
										ref={multipleRef}
										label={
											<p className='text-sm leading-5 font-normal text-gray-800 mb-1 mt-5'>
												Additional Contacts (Optional)
											</p>
										}
										onChange={(members) => {
											setValue('cc_emails', members);
											if (user?.original_customer) {
												const idx = members.findIndex(
													({ value }) =>
														value === user?.original_customer.customers_email_address
												);
												setAddRecipient(idx > -1 ? 1 : 0);
											}
										}}
										values={watch('cc_emails')}
										placeholder={
											<p className='text-sm leading-5 font-normal text-gray-500'>
												Enter emails separated by a comma “,”
											</p>
										}
										errors={errors}
										setError={setError}
										clearErrors={clearErrors}
										isSubmitted={!!isSubmitted}
										min={0}
										limit={3}
									/>
								</div>
							</>
						)}
					</div>
					<div className='flex flex-row mt-8'>
						<div className='shadow-sm py-4 px-6 w-full' style={{ background: '#F0F8FF' }}>
							<p className='text-sm leading-5 font-semibold text-gray-700'>Note</p>
							<p className='text-sm leading-5 font-normal text-gray-600'>
								{texts[mode][assessmentType].note}
							</p>
						</div>
					</div>
					{!agree && (
						<div className='flex flex-1 mt-4'>
							<AppCheckBox
								{...register('agree', {
									required: true,
								})}
								errors={errors}
								label={() => {
									return (
										<p className={'text-primary font-semibold'}>
											I have read and understood the information above.
										</p>
									);
								}}
							/>
						</div>
					)}
				</div>
				<AppErrorMessage message={errorMessage} />
				<div className='flex justify-between my-4'>
					<AppButton
						className={'btn btn-secondary'}
						type={'button'}
						text={'Back'}
						onClick={previousStep}
						disabled={loading}
						id={'wg-setup-assessment-back-btn'}
					/>
					<AppButton
						text={'Generate link'}
						type={'submit'}
						loading={loading}
						id={'wg-setup-assessment-generate-link-btn'}
					/>
				</div>
			</form>
		</div>
	);
};

export default AssessmentInformation;
