import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../../components/Layout';
import { getQueryParams } from '../../../services/UtilityService';

const RegisterInvitation = () => {
	const location = useLocation();
	const { token } = getQueryParams(location.search);

	const handleRedirect = React.useCallback(() => {
		if(token)
		return navigate(`/client/register?license_token=${token}`, { replace: true });

		return navigate(`/client/register`, { replace: true });
	}, [navigate, token]);

	React.useEffect(handleRedirect, []);

	return (
		<Layout title='Register | The Six Types of Working Genius'>
			<div className='container mx-auto'><div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
			</div>
		</Layout>
	);
};

export default RegisterInvitation;
