import React from 'react';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import StoreHero from '../../components/store/StoreHero';
import Products from '../../components/store/Products';
import WhatElseWeOffer from '../../components/store/WhatElseWeOffer';
import { sortProducts } from '../../services/CommerceApiService';
import { StoreStructuredMetaData } from '../../components/store/StoreStructuredMetaData';
import { useCheckout } from "@ttg/ui-checkout-lib";

const StorePage = () => {
	const {customHooks: {productHook: {products, loadingProducts}}} = useCheckout();
	const allProducts = React.useMemo(() => products && products.length && !loadingProducts ? sortProducts(products): null, [products, loadingProducts]);

	React.useEffect(() => {
		if(allProducts) {
			setTimeout(() => {
				const anchor = window.location.hash.slice(1);
				if (anchor) {
					const anchorEl = document.getElementById(anchor);
					if (anchorEl) {
						anchorEl.scrollIntoView();
					}
				}
			}, [300]);
		}
	}, [allProducts]);

	return (
		<WorkshopLayout title={'Store  | The 6 Types of Working Genius'}>
			<StoreHero />
			{loadingProducts ? (
				<div id={'events-loading-spinner'} className='relative'>
					<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center my-8'>
						<div className='spinner'>
							<div className='double-bounce1' />
							<div className='double-bounce2' />
						</div>
					</div>
				</div>
			) : (
				<>
					<StoreStructuredMetaData products={allProducts.bestSellers.concat(allProducts.merchandise)} />
					<div id={'best-seller'}>
						<Products title='Best-Sellers' products={allProducts.bestSellers} />
					</div>
					<div id={'merch'}>
						<Products title='Merchandise' products={allProducts.merchandise} />
					</div>
					<div id={'other'}>
						<WhatElseWeOffer />
					</div>
				</>
			)}
		</WorkshopLayout>
	);
};

export default StorePage;
