import React from 'react';
import AppNavbar from './AppNavbar';
import AppFooter from './AppFooter';
import AppTabTitle from './AppTabTitle';
import AppTopBar from './AppTopBar';

const Layout = ({
	children,
	containerClass,
	wrapperClass,
	title = 'The 6 Types of Working Genius: Administrator Console',
	hasFooter = true,
	footerTheme = 'light',
	style = {}
}) => {
	return (
		<div className={`flex flex-col min-h-screen ${containerClass ? containerClass : ''}`} style={style}>
			<AppTabTitle title={title} />
			<AppTopBar />
			<AppNavbar />
			<div className={`flex flex-1 flex-col ${wrapperClass ? wrapperClass : ''}`}>{children}</div>
			{hasFooter && <AppFooter theme={footerTheme} />}
		</div>
	);
};

export default Layout;
