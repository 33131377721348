import {
	Account,
	AccountUpdated,
	AssessmentContact,
	AssessmentRoster,
	AssessmentDetails,
	AssessmentReport,
	AssessmentSetup,
	Book,
	Certification,
	CertificationPreWork,
	AlumniIntensives,
	Client,
	EmailChangeVerification,
	Home,
	Live,
	LoginReminder,
	LoginReset,
	ResourceDetails,
	TakeAssessments,
	TakeGroupAssessment,
	Teammap,
	TeamMapReport,
	Tools,
	Register,
	FindAssessments,
	Store,
	Workshop,
	LaunchEvent,
	Event,
	Trainee,
	Resources,
	ArticleDetails,
	Faq,
	ExclusiveWebinar,
	LeadersToolkit,
	RequestShareVerification,
	FixYourFrustration,
	CertWebinar,
	RegisterInvitation,
	Licenses,
	RequestAssessments,
	CareersEventRegistration,
	CareersEvent,
	ShoppingCartDetails,
	CheckoutDetails,
	November8Event,
	SignupPage,
	StudentsPage,
	TeamWorkQuiz,
	APIDocs,
	APITermsOfService,
	StorePage,
	DCA,
	DCAClaimResources,
	PodcastPdf,
	Webinar,
	Permissions,
	NotAuthorized,
	NotFound,
	AlumniIntensivesResourceDetails,
	SummaryReportPdf
} from '../views';
import Product from '../views/toolkit/product';
import PreWork from '../views/workshop/prework';
import RequestAssessmentShareVerification from '../views/client/request-assessments/sharing';
import PolicyPage from '../views/policy/PolicyPage';
import Policy from '../views/policy/Policy';
import AboutLayout from '../views/about';
import AboutPage from '../views/about/AboutPage';
import AssessmentAbout from '../views/about/assessment';
import UsingAdministratorConsole from '../views/using-admin-conosle';
import PodcastPage from "../views/podcast/PodcastPage";
import Podcast from "../views/podcast/Podcast";
import PodcastDetails from "../views/podcast/PodcastDetails";
import ConfirmPermissionRequest from '../views/client/permissions/request';
import config from "../constants/config";

export const ProtectedRoutes = [
	{ path: '/client/accounts', component: Account, permission: 'USER.PUT' },
	{ path: '/client/account/save/details', component: AccountUpdated },
	{ path: '/client/account', component: Account, permission: 'USER.PUT' },
	{ path: '/client', component: Client },
	{ path: '/live', component: Live },
	{ path: '/client/assessment/contact', component: AssessmentContact },
	{ path: '/client/assessment', component: AssessmentDetails },
	{ path: '/client/assessment_roster', component: AssessmentRoster },
	{ path: '/client/assessment/setup', component: AssessmentSetup },
	{ path: '/client/certification/prework', component: CertificationPreWork, permission: 'WG_CERTIFICATION.GET' },
	{ path: '/client/tools', component: Tools },
	{ path: '/client/certification/resources/post', component: ResourceDetails, permission: 'WG_CERTIFICATION.GET' },
	{ path: '/client/alumni-intensives/resources/post', component: AlumniIntensivesResourceDetails, permission: 'WG_CERTIFICATION.GET' },
	{ path: '/client/dca-resources', component: DCAClaimResources },
	{ path: '/client/teammap', component: Teammap },
	{ path: '/client/trainee', component: Trainee },
	{ path: '/client/licenses', component: Licenses, permission: 'WG_LICENSE.GET.LIST' },
	{ path: '/client/request-assessments', component: RequestAssessments, permission: 'WG_ASSESSMENT.GET.REQUESTS' },
	{ path: '/application-guide-product', component: LeadersToolkit, permission: 'WG_TOOLKIT' },
	{ path: '/checkout-view', component: CheckoutDetails, permission: 'CHECKOUT' },
	{ path: '/docs-api', component: APIDocs },
	{ path: '/client/permissions', component: Permissions, permission: 'USER.PERMISSION' }
];

export const CommonRoutes = [
	{ path: '/', component: Home },
	{ path: '/certification', component: Certification },
	{ path: '/client/certification', component: Certification },
	{ path: '/client/alumni-intensives', component: config.alumni_intensive_feature ? AlumniIntensives : NotFound },
	{ path: '/client/register', component: Register },
	{ path: '/client/find', component: FindAssessments },
	{ path: '/client/login/reminder', component: LoginReminder },
	{ path: '/client/login/reset', component: LoginReset },
	{ path: '/client/login/email-change-verification', component: EmailChangeVerification },
	{ path: '/assessments/take', component: TakeAssessments },
	{ path: '/assessment/:slug', component: TakeGroupAssessment },
	{ path: '/assessment/:slug/:slug2/', component: TakeGroupAssessment },
	{ path: '/assessment/:slug/:slug2/:slug3', component: TakeGroupAssessment },
	{ path: '/assessment/:slug/:slug2/:slug3/:slug4', component: TakeGroupAssessment },
	{ path: '/assessments/report', component: AssessmentReport },
	{ path: '/book', component: Book },
	{ path: '/Book', component: Book },
	{ path: '/random-store-url-hidden', component: Store },
	{ path: '/workshop', component: Workshop },
	{ path: '/launcheventsignup', component: LaunchEvent },
	{ path: '/random-hidden-url', component: Event },
	{
		path: '/about',
		component: AboutLayout,
		children: [
			{
				path: '/',
				component: AboutPage,
			},
			{
				path: 'assessment',
				component: AssessmentAbout,
			},
		],
	},
	{ path: '/resources', component: Resources },
	{ path: '/resources/article/details', component: ArticleDetails },
	{ path: '/faq', component: Faq },
	{ path: '/faqs', component: Faq },
	{ path: '/exclusivewebinar', component: ExclusiveWebinar },
	{ path: '/Exclusivewebinar', component: ExclusiveWebinar },
	{ path: '/fixyourfrustration', component: FixYourFrustration },
	{ path: '/client/sharing', component: RequestShareVerification },
	{ path: '/client/claim-licenses', component: RegisterInvitation },
	{ path: '/dca-private-certification-registration', component: DCA },
	{ path: '/teamapplicationguide', component: Product },
	{ path: '/workshop-pre-work', component: PreWork },
	{ path: '/jobscareersandhiringreg', component: CareersEventRegistration },
	{ path: '/jobscareersandhiringevent', component: CareersEvent },
	{ path: '/atthewhiteboardmeetingsevent', component: November8Event },
	{ path: '/assessment-request-share', component: RequestAssessmentShareVerification },
	{ path: '/podcast', component: Podcast },
	{
		path: '/policy/',
		component: Policy,
		children: [
			{
				path: 'privacy-policy',
				component: PolicyPage,
				props: {
					type: 'privacy',
				},
			},
			{
				path: 'terms-of-service',
				component: PolicyPage,
				props: {
					type: 'terms',
				},
			},
		],
	},
	{
		path: '/wg-podcast-post',
		component: Podcast,
		children: [
			{
				path: '/',
				component: PodcastPage,
				props: {
					type: 'main',
				}
			},
			{
				path: '/:slug',
				component: PodcastDetails,
				props: {
					type: 'details',
				}
			}
		]
	},
	{ path: 'using-administrator-console', component: UsingAdministratorConsole },
	{ path: '/shopping-cart-view', component: ShoppingCartDetails },
	{ path: '/client/signup', component: SignupPage },
	{ path: '/students', component: StudentsPage },
	{ path: '/teamworkquiz', component: TeamWorkQuiz },
	{ path: '/certwebinar', component: CertWebinar },
	{ path: '/terms-api', component: APITermsOfService },
	{ path: '/store', component: StorePage },
	{ path: '/webinars', component: Webinar, props: { stream: false } },
	{ path: '/webinarstream', component: Webinar, props: { stream: true } },
	{ path: '/permission-request-verify', component: ConfirmPermissionRequest },
	{ path: '/not-authorized', component: NotAuthorized }
];

export const PdfRoutes = [
	{ path: '/pdf/assessment/assessmentReport', component: AssessmentReport },
	{ path: '/pdf/teamMap/teamMapReport', component: TeamMapReport },
	{ path: '/pdf/podcast/podcastPdf', component: PodcastPdf },
	{ path: '/pdf/report/summary', component: SummaryReportPdf }
]