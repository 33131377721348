import React from 'react';
import { CheckoutProvider } from '@ttg/ui-checkout-lib';
import { Location } from '@reach/router';
import SwaggerUI from 'swagger-ui-react';
import { Helmet } from 'react-helmet';

import '@ttg/ui-checkout-lib/index.css';
import '@ttg/ui-common-lib/index.css';
import 'remixicon/fonts/remixicon.css';
import './src/styles/index.scss';

import { PdfRoutes } from './src/routes/routes';
import config from './src/constants/config';

export const wrapRootElement = ({ element }) => {
	return (
		<Location>
			{({ location }) => {
				if (!PdfRoutes.map((route) => route.path).includes(location.pathname)) {
					return (
						<>

							<CheckoutProvider
								applicationId={'WG'}
								apiEndpoint={config.ttg_url}
								captchaEnabled={config.captcha_enabled}
								captchaSiteKey={config.captcha_site_key}
							>
								<Helmet>
									<script type='text/javascript' src='/weglot.js' />
									<script type='text/javascript' src='/tally.js' />
								</Helmet>
								{element}
							</CheckoutProvider>
						</>
					);
				}

				return element;
			}}
		</Location>
	);
};

window.SwaggerUI = SwaggerUI;
