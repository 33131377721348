import React from 'react';
import { Helmet } from 'react-helmet';
import { getProductStructuredData } from './StructuredMetaDataUtility';

export const ProductStructuredMetaData = ({ product }) => {
	const structuredData = getProductStructuredData(product);

	return (
		<Helmet>
			<script type='application/ld+json'>{JSON.stringify(structuredData)}</script>
		</Helmet>
	);
};
