import React from 'react';
import { Link } from 'gatsby';

import AppTable from '../../../components/AppTable';
import LicenseFilters from '../../../components/license/LicenseFilters';
import AppButton from '../../../components/AppButton';
import AppRIIcon from '../../../components/AppRIIcon';
import TransferLicensesModal from '../../../components/license/TransferLicensesModal';
import NotificationService from '../../../services/NotificationService';
import LicenseService from '../../../services/LicenseService';
import { addToCart } from '../../../services/CustomerService';
import ConfirmTransfer from '../../../components/license/ConfirmTransferModal';
import UtilityService from '../../../services/UtilityService';
import WgIcon from '../../../components/WgIcon';
import { useUser } from '../../../hooks/UserProvider';

const noDataMessage = () => (
	<div className={'flex flex-col justify-center pt-2 mt-5'}>
		<AppRIIcon icon='file-shield-2-line' className='text-6xl text-gray-600 mb-5' />
		There are no licenses.
	</div>
);

const Licenses = ({ orders, assigned, adjustments, setAdjustments }) => {
	const initialFilters = {
		sort_key: 'created',
		sort_dir: 'desc',
		page: 0,
		limit: 25,
	};
	const [transferModal, setTransferModal] = React.useState(false);
	const [cancelTransferLicense, setCancelTransferLicense] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [loadingMore, setLoadingMore] = React.useState(false);
	const [filters, setFilters] = React.useState(initialFilters);
	const [assignedOnly, setAssignedOnly] = React.useState(0);
	const [licenses, setLicenses] = React.useState({
		data: [],
		meta: [],
	});
	const [hasLicenseHistory, setHasLicenseHistory] = React.useState(-1);
	const hasLicenses = () => orders + adjustments > 0;
	const { setUser } = useUser();

	const getLicenses = (_filters = filters, loader = setLoading, merge = false) => {
		loader(true);
		LicenseService.list(_filters)
			.then((data) => {
				const _licenses = merge
					? {
							meta: data.meta,
							data: [...licenses.data, ...data.data],
					  }
					: data;
				setLicenses(_licenses);
				setAssignedOnly(_licenses.data.reduce((acc, item) => acc + item.quantity, 0));

				if (hasLicenseHistory === -1) {
					setHasLicenseHistory(data.data.length > 0 ? 1 : 0);
				}
			})
			.catch((err) => {
				NotificationService.error('Error', err.message);
			})
			.finally(() => loader(false));

		return true;
	};

	const cancelTransfer = () => {
		setLoading(true);

		LicenseService.cancelTransfer({ id: cancelTransferLicense.id })
			.then(() => {
				NotificationService.info('Transfer Cancelled', 'The pending transfer has been cancelled');
				setUser((u) => ({ ...u, licenses: cancelTransferLicense.quantity + u.licenses }));
				setAdjustments(adjustments + cancelTransferLicense.quantity);
				getLicenses();
				setCancelTransferLicense({});
			})
			.catch((err) => {
				NotificationService.error('Error', err.message);
			})
			.finally(() => setLoading(false));
	};

	React.useEffect(() => {
		getLicenses();
	}, [filters.status, filters.search]);

	const onChangeFilter = (cb) => {
		setFilters((c) => ({ ...c, ...cb(), page: 0 }));
	};

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir,
		};
		setFilters(newFilters);
		getLicenses(newFilters);
	};

	const onPageChange = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getLicenses(newFilters, setLoadingMore, true);
	};

	const onCloseModal = (data) => {
		if (data) {
			NotificationService.info(
				'Successfully Transferred',
				`You have successfully transferred ${data.licenses} license${data.licenses > 1 ? 's' : ''} to  ${
					data.email
				}.`
			);
			setAdjustments(adjustments - data.licenses);
			getLicenses();
		}

		setTransferModal(false);
	};

	const columns = [
		{
			header: 'Name',
			key: 'description',
			className: 'md:w-2/6',
			render: (item) => {
				switch (item.status) {
					case 'Purchased':
						return (
							<div id={'wg-licenses-name-col'}>
								<Link to={'/client/account#orders'} className='flex items-center'>
									<AppRIIcon icon='user-fill' className='text-2xl text-secondary mr-4' />
									<p className='text-primary'>{item.description}</p>
								</Link>
							</div>
						);

					case 'Assigned':
						return (
							<div id={'wg-licenses-name-col'}>
								<Link to={`/client/assessment/?id=${item.id}`} className='flex items-center'>
									<WgIcon
										item={item}
										className={`text-2xl mr-4 ${item.assessment_mode === 'student' ? 'mx-1' : ''}`}
									/>
									<p className='underline cursor-pointer'>{item.description}</p>
								</Link>
							</div>
						);
				}
				return (
					<div className='flex items-center text-primary'>
						<AppRIIcon icon='mail-send-fill' className='text-2xl text-secondary ml-2 mr-4' />
						{item.description}
					</div>
				);
			},
		},
		{
			header: 'Date Created',
			key: 'created',
			className: 'md:w-1/6',
			render: (item) => {
				return (
					<>
						<p className='text-primary'>{UtilityService.formatDate(item.created)}</p>
					</>
				);
			},
		},
		{
			header: 'Licenses',
			key: 'quantity',
			className: 'md:w-1/6',
			render: (item) => {
				return (
					<>
						<p className='text-primary'>{item.quantity}</p>
					</>
				);
			},
		},
		{
			header: 'Status',
			key: 'status',
			className: 'md:w-1/6',
			render: (item) => {
				return (
					<>
						<p className='text-primary text-sm leading-5 font-bold'>{item.status}</p>
					</>
				);
			},
		},
		{
			header: 'Action',
			key: 'action',
			className: 'md:w-1/6',
			sortable: false,
			render: (item) => {
				return (
					<p
						className='text-red-500 text-sm leading-5 font-bold cursor-pointer'
						onClick={() => setCancelTransferLicense(item)}
					>
						{item.action}
					</p>
				);
			},
		},
	];

	const renderHeader = () => (
		<div className='flex flex-col'>
			<div className='license-container'>
				<div className='license-header-container'>
					<h1 className='text-primary text-4xl font-semibold mt-6 mb-2'>License usage</h1>
					<p className='text-base leading-6 font-medium text-gray-600'>
						Below is a table containing your license usage by assessment.
					</p>
				</div>
				<div className='flex items-center'>
					<AppButton
						className='btn btn-secondary'
						text='Purchase Licenses'
						remixIcon
						icon='share-box-fill'
						iconClass='mx-2 my-0'
						onClick={() => addToCart('add_license', true)}
					/>
					<AppButton
						disabled={!hasLicenses()}
						className='btn btn-primary ml-4'
						text='Transfer Licenses'
						onClick={() => setTransferModal(true)}
					/>
				</div>
			</div>
			<div className='flex items-center mb-2'>
				<div className='border-black border-r-1 py-2 text-center'>
					<span className='text-base leading-6 font-medium text-gray-600 mr-5'>
						<span id={'wg-licenses-number'}>{assignedOnly}</span> assigned license{assigned !== 1 && 's'}
					</span>
				</div>
				<div className='py-2 text-center'>
					<span className='text-base leading-6 font-medium text-gray-600 mx-5'>
						<span id={'wg-licenses-number'}>{orders + adjustments - assigned}</span> unused license
						{orders + adjustments - assigned !== 1 && 's'}
					</span>
				</div>
			</div>
		</div>
	);

	return (
		<div id='wg-licenses-container'>
			<div className='flex-col'>
				{renderHeader()}
				{(hasLicenseHistory > 0 || hasLicenses()) && (
					<AppTable
						renderFilters={LicenseFilters}
						wrapperClassname='mx-auto'
						columns={columns}
						data={licenses.data}
						noDataMessage={noDataMessage()}
						loading={loading}
						onSort={onSort}
						filters={filters}
						setFilters={onChangeFilter}
						onLoadMore={onPageChange}
						loadingMore={loadingMore}
						onSearch={() => {
							setFilters({ ...filters, page: 0 });
							getLicenses();
						}}
						onSearchVisible={false}
						pagination={licenses.meta}
					/>
				)}
				{hasLicenseHistory === 0 && !hasLicenses() && !loading && (
					<div className='flex flex-col mt-20 justify-center items-center'>
						<AppRIIcon icon='file-shield-2-line' className='text-6xl text-gray-600' />
						<p className='text-center text-base leading-6 font-semibold text-gray-600 mt-5'>
							You have 0 licenses in your account. <br />
							Click the button below to get some licenses in order to create your first assessment.
						</p>
						<AppButton
							className='btn btn-primary ml-4 mt-10'
							text='Purchase Licenses'
							remixIcon
							icon='share-box-fill'
							iconClass='mx-2 my-0'
							onClick={() => addToCart('add_license', true)}
						/>
					</div>
				)}
			</div>
			<TransferLicensesModal
				isOpen={transferModal}
				onCloseModal={onCloseModal}
				totalLicenses={orders + adjustments - assigned}
			/>
			<ConfirmTransfer
				loading={loading}
				isOpen={!!cancelTransferLicense.id}
				onClose={() => setCancelTransferLicense({})}
				onConfirm={cancelTransfer}
				licenses={licenses}
				text='Are you sure you wish to cancel the transfer?'
				confirmText='Yes, Cancel Transfer'
				title='Cancel Transfer'
			/>
		</div>
	);
};

export default Licenses;
