import React from 'react';
import AppModal from './AppModal';
import AppRIIcon from './AppRIIcon';

const AppSlideOver = ({ isOpen, closeModal, children, containerClassName = 'slide-modal', shouldFocusAfterRender = false }) => {
	return (
		<AppModal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className={`${containerClassName} flex flex-row`}
			overlayClassName='slide-overlay'
			shouldFocusAfterRender={shouldFocusAfterRender}
		>
			<div>
				<button onClick={closeModal} className='slide-over-btn'>
					<AppRIIcon icon='close-fill' className='slide-over-icon text-2xl text-white' />
				</button>
			</div>
			<div className='slide-over-content'>
				<div className='flex flex-1'>{children}</div>
			</div>
		</AppModal>
	);
};

export default AppSlideOver;
