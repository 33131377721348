export { default as PatImage } from './pat2.jpg';
export { default as PatExplains } from './pat_explains_model.png';
export { default as WGreport } from './wgreport.jpg';
export { default as WGpodcast } from './wgpodcast.jpg';
export { default as WGpodcastPNG } from './wgpodcast.png';
export { default as ttg_logo_white } from './logos/ttg_powered_logo_wht.svg';
export { default as ttg_icon } from './logos/ttg_icon.svg';
export { default as wg_logo_rev } from './logos/wg_logo_rev.svg';
export { default as doublequotes_icon } from './logos/doublequotes_icon.svg';
export { default as bg_whiteboard } from './bg_whiteboard.jpg';
export { default as bg_whiteboard_mirrored } from './bg_whiteboard_mirrored.jpg';
export { default as wg_logo_primary } from './logos/wg_logo_primary.svg';
export { default as ttg_powered_logo } from './logos/ttg_powered_logo.svg';
export { default as widget_model } from './widget_model.png';
export { default as individualgears_w } from './logos/individualgears_w.svg';
export { default as individualgears_i } from './logos/individualgears_i.svg';
export { default as individualgears_d } from './logos/individualgears_d.svg';
export { default as individualgears_g } from './logos/individualgears_g.svg';
export { default as individualgears_e } from './logos/individualgears_e.svg';
export { default as individualgears_t } from './logos/individualgears_t.svg';
export { default as cert_logo } from './logos/cert-logo.png';
export { default as cert_logo_de } from './logos/cert-logo-de.png';
export { default as cert_logo_es } from './cert_logo_spanish.png';
export { default as phone } from './logos/phone.svg';
export { default as mail } from './logos/mail.svg';
export { default as favicon } from './favicon.ico';
export { default as cert_section_1 } from './cert-section-1.jpg';
export { default as cert_session_2 } from './cert-section-2.jpg';
export { default as cert_section_3 } from './cert-section-3.png';
export { default as cert_footer } from './cert-footer.png';
export { default as cert_table } from './cert-table.jpg';
export { default as pat2 } from './pat2.jpg';
export { default as cert_notification } from './cert-notification.svg';
export { default as cert_doublequotes } from './cert-doublequotes.svg';
export { default as gear_overlay } from './gear_overlay.png';
export { default as clipboard } from './clipboard.svg';
export { default as send_assessment } from './6g_send_assesment.svg';
export { default as tools_icon } from './tools_icon.svg';
export { default as tools_printer } from './logos/tools_printer.svg';
export { default as tools_video } from './logos/tools_video.svg';
export { default as tools_mic } from './logos/tools_mic.svg';
export { default as wg_live_screenshot } from './wg_live_screenshot.jpg';
export { default as prework_incomplete_step } from './logos/prework-incomplete-step.svg';
export { default as prework_complete_step } from './logos/prework-complete-step.svg';
export { default as povlarge } from './povlarge.jpeg';
export { default as cover_assessment_report } from './assessments/report/img/cover/assessment-report.svg';
export { default as cover_assessment_report_es } from './assessments/report/img/cover/assessment-report-es.svg';
export { default as cover_gears } from './assessments/report/img/cover/gears.svg';
export { default as cover_ttg } from './assessments/report/img/cover/ttg.svg';
export { default as cover_logo } from './assessments/report/img/logo.svg';
export { default as cover_logo_es } from './assessments/report/img/logo_es.svg';
export { default as cover_logo_de } from './assessments/report/img/cover_logo_de.png';
export { default as report_overview_gear_w } from './assessments/report/img/gear-w.svg';
export { default as report_overview_gear_i } from './assessments/report/img/gear-i.svg';
export { default as report_overview_gear_d } from './assessments/report/img/gear-d.svg';
export { default as report_overview_gear_g } from './assessments/report/img/gear-g.svg';
export { default as report_overview_gear_e } from './assessments/report/img/gear-e.svg';
export { default as report_overview_gear_t } from './assessments/report/img/gear-t.svg';
export { default as report_overview_gear_p } from './assessments/report/img/gear-p.svg';
export { default as report_overview_gear_c } from './assessments/report/img/gear-c.svg';
export { default as report_overview_gear_f } from './assessments/report/img/gear-f.svg';
export { default as report_overview_gear_h } from './assessments/report/img/gear-h.svg';
export { default as report_overview_gear_m } from './assessments/report/img/gear-m.svg';
export { default as report_overview_gear_u } from './assessments/report/img/gear-u.svg';
export { default as report_overview_gear_b } from './assessments/report/img/gear-b.svg';
export { default as report_footer_gear } from './assessments/report/img/footer/gear.svg';
export { default as report_company_logo_preview } from './assessments/report/report_preview.svg';
export { default as report_gear } from './assessments/report/img/gear.svg';
export { default as qualitative_bars } from './assessments/report/img/qualitative/bars.svg';
export { default as qualitative_plus } from './assessments/report/img/qualitative/plus.svg';
export { default as qualitative_checkboxes } from './assessments/report/img/qualitative/checkboxes.svg';
export { default as qualitative_equals } from './assessments/report/img/qualitative/equals.svg';
export { default as qualitative_gear_li } from './assessments/report/img/qualitative/gear-li.svg';
export { default as report_hr } from './assessments/report/img/hr.png';
export { default as report_hr_sm } from './assessments/report/img/hr-sm.png';
export { default as report_li } from './assessments/report/img/li.svg';
export { default as genius_team_widget } from './assessments/report/img/genius-teams/widget.svg';
export { default as genius_team_es_widget } from './assessments/report/img/genius-teams/widget_es.svg';
export { default as genius_team_scale } from './assessments/report/img/genius-teams/scale.svg';
export { default as genius_team_es_scale } from './assessments/report/img/genius-teams/scale_es.svg';
export { default as going_deep_video_1 } from './assessments/report/img/going-deeper/video-1.jpg';
export { default as going_deep_play } from './assessments/report/img/going-deeper/play.svg';
export { default as wg_logo } from './wg_logo.svg';
export { default as poweredby } from './poweredby.svg';
export { default as christmas_decoration } from './christmas_decoration.svg';
export { default as avatar } from './avatar.png';
export { default as widget_launch_background } from './widget-launch-background.jpg';
export { default as book_cover } from './book-cover.png';
export { default as patrick_shadow } from './patrick-shadow.png';
export { default as free_assessment } from './free-assessment.svg';
export { default as digital_access } from './digital-access.svg';
export { default as exclusive_updates } from './exclusive-updates.svg';
export { default as book_cover_with_background } from './book-cover-with-background.jpg';
export { default as book_amazon } from './book-amazon.jpg';
export { default as book_bam } from './book-bam.jpg';
export { default as book_barnes } from './book-barnes.jpg';
export { default as join_the_launch_team_background } from './join-launch-team-background.jpg';
export { default as book_play_video } from './book-play-video.png';
export { default as patrick_plain } from './patrick-plain.jpg';
export { default as workshop_home_image } from './workshop-home-image.jpg';
export { default as workshop_productivity } from './workshop-productivity.svg';
export { default as workshop_meetings } from './workshop-meetings.svg';
export { default as workshop_morals } from './workshop-morals.svg';
export { default as workshop_hero_image } from './workshop-hero-image.jpg';
export { default as widget_hr } from './widget-hr.svg';
export { default as launch_event_hero_background } from './launch-event-hero-background.jpg';
export { default as mixbook } from './mixbook.png';
export { default as new_york_times } from './new_york_times.jpeg';
export { default as orange_theory } from './orange_theory.png';
export { default as vanderbilt } from './vanderbilt.png';
export { default as linkage } from './linkage.png';
export { default as launch_event_about_background } from './launch-event-about-background.jpg';
export { default as book_3d } from './book-3d.png';
export { default as launch_event_widgets } from './launch-event-widgets.png';
export { default as spread_icon } from './spread.svg';
export { default as widget_play } from './widget-play.svg';
export { default as ttg_logo_event } from './ttg-logo-event.png';
export { default as event_free_resource } from './event-free-resource.jpg';
export { default as event_podcast } from './event-podcast.jpg';
export { default as event_get_certified } from './event-get-certified.jpg';
export { default as gear_w } from './gear-w.svg';
export { default as gear_i } from './gear-i.svg';
export { default as gear_d } from './gear-d.svg';
export { default as gear_g } from './gear-g.svg';
export { default as gear_e } from './gear-e.svg';
export { default as gear_t } from './gear-t.svg';
export { default as gear_p } from './gear-p.png';
export { default as gear_c } from './gear-c.png';
export { default as gear_f } from './gear-f.png';
export { default as gear_h } from './gear-h.svg';
export { default as gear_m } from './gear-m.svg';
export { default as gear_u } from './gear-u.svg';
export { default as gear_b } from './gear-b.svg';
export { default as gear_student } from './assessments/report/img/cover/gearStudent.svg';
export { default as full_focus } from './full-focus-logo.png';
export { default as CoverLogoV2 } from './assessments/report/img/cover/CoverLogoV2.png';
export { default as CoverLogoV2ES } from './assessments/report/img/cover/CoverLogoV2_ES.png';
export { default as CoverLogoV2DE } from './assessments/report/img/cover/CoverLogoV2_DE.png';
export { default as CoverLogoV2Student } from './assessments/report/img/cover/CoverLogoV2_Student.png';
export { default as CoverBreakLine } from './assessments/report/img/cover/CoverBreakLine.png';
export { default as TTGWhiteReportLogo } from './assessments/report/img/cover/TTGWhiteReportLogo.png';
export { default as WidgetReportV2 } from './assessments/report/img/WIDGET-site-2022-Book-Image-9.png';
export { default as WidgetReportV2Spanish } from './assessments/report/img/WIDGET-site-2022-spanish.png';
export { default as WidgetReportV2Deutsch } from './assessments/report/img/WIDGET-site-2024-deutsch.png';
export { default as WidgetReportStudent } from './assessments/report/img/widget-primary.png';
export { default as ReportGearsHeader } from './assessments/report/img/ReportGearsHeader.png';
export { default as StudentReportGearsHeader } from './assessments/report/img/StudentReportGearsHeader.png';
export { default as team_map_analyze } from './assessments/report/img/TeamMapAnalyze.png';
export { default as team_map_small_report_arrow } from './assessments/report/img/SmallReportArrow.png';
export { default as team_map_large_report_arrow } from './assessments/report/img/LargeReportArrow.png';
export { default as free_tool_and_resources } from './assessments/report/img/going-deeper/free-tools-and-resources.png';
export { default as report_book } from './assessments/report/img/going-deeper/book.png';
export { default as report_certification } from './assessments/report/img/going-deeper/certification.png';
export { default as report_podcast } from './assessments/report/img/going-deeper/podcast.png';
export { default as report_qr_section } from './assessments/report/img/going-deeper/wg_qr_section.png';
export { default as jon_gordon } from './jon-gordon.png';
export { default as john_maxwell } from './john-maxwell.png';
export { default as michelle_rashid } from './michelle-rashid.png';
export { default as dave_ramsey } from './dave-ramsey.png';
export { default as gears_play_video } from './gears-play-video.png';
export { default as about_how_it_started } from './about-how-it-started.jpg';
export { default as book_3d_background } from './book_3d_background.png';
export { default as about_page_human_gears } from './about_page_human_gears.jpg';
export { default as about_book_cover_3d } from './about-book-cover-3d.png';
export { default as wg_about_pat_book_cover } from './wg-about-pat-book-cover.jpg';
export { default as about_the_assessment } from './about_the_assessment.jpg';
export { default as about_takes_10_minutes } from './about_takes_10_minutes.png';
export { default as about_the_assessment_desk } from './about-the-assessment-desk.png';
export { default as about_the_assessment_reports } from './about-the-assessment-reports.png';
export { default as about_the_assessment_schedule } from './about-the-assessment-schedule.jpg';
export { default as about_the_assessment_team_map } from './about-the-assessment-team-map.png';
export { default as widget_bg } from './widget-bg.jpg';
export { default as widget_bg_about_dropdown } from './widget-bg-about-dropdown.png';
export { default as logo } from './logo.svg';
export { default as ebook } from './ebook.png';
export { default as home_why_wg } from './home-why-wg.png';
export { default as wg_home_workshop } from './wg-home-workshop.jpg';
export { default as wg_home_workshop_devices } from './wg-home-workshop-devices.png';
export { default as wg_home_certification } from './wg-home-certification.jpg';
export { default as home_resources_for_individuals } from './home-resources-for-individuals.jpg';
export { default as workshop_group_image } from './workshop-group-img.jpg';
export { default as sample_teammap } from './sample_teammap.png';
export { default as certification_group_image } from './certification-group.jpg';
export { default as certification_table } from './certification-table.png';
export { default as certification_pat } from './certification-pat.png';
export { default as certification_bg } from './certification-bg.png';
export { default as resources_dropdown_header_image } from './resources_dropdown_header_image.png';
export { default as resources_hero_bg } from './resources-hero-bg.jpeg';
export { default as resources_hero } from './resources-hero.png';
export { default as scrollable_header_bg } from './scrollable-header-bg.png';
export { default as resource_request_workshop } from './resource-request-workshop.svg';
export { default as resource_apple_podcasts } from './resources-apple-podcasts.png';
export { default as resource_spotify_podcasts } from './resources-spotify-podcasts.png';
export { default as resource_google_podcasts } from './resources-google-podcasts.png';
export { default as resource_pat_podcast } from './resource-pat-podcast.webp';
export { default as resource_atthetable_podcast } from './resource-atthetable-podcast.jpeg';
export { default as report_team_map_analyze } from './report-team-map-analyze.png';
export { default as report_team_map_analyze_es } from './report-team-map-analyze-es.png';
export { default as report_team_map_analyze_de } from './report-team-map-analyze-de.png';
export { default as resources_articles } from './resources-articles.svg';
export { default as resources_podcasts } from './resources-podcasts.svg';
export { default as resources_team } from './resources-team.svg';
export { default as gears } from './gears.svg';
export { default as webinar_bg } from './webinar-bg.png';
export { default as live_webinar } from './live-webinar.png';
export { default as webinar_teammap } from './team-map-img.png';
export { default as toolkit_logo } from './toolkit-logo.png';
export { default as toolkit_resources } from './toolkit-resources.svg';
export { default as popup_book } from './book-popup.png';
export { default as popup_assessment } from './assessment-popup.png';
export { default as toolkit_teammap_thumbnail } from './toolkit-teammap-thumbnail.png';
export { default as toolkit_6wg_thumbnail } from './toolkit-6wg-thumbnail.png';
export { default as toolkit_meetings_thumbnail } from './toolkit-meetings-thumbnail.png';
export { default as toolkit_hiring_thumbnail } from './toolkit-hiring-thumbnail.png';
export { default as toolkit_send_assessment_thumbnail } from './toolkit-send-assessment-thumbnail.png';
export { default as toolkit_create_teammap_thumbnail } from './toolkit-create-teammap-thumbnail.png';
export { default as toolkit_share_thumbnail } from './toolkit-share-thumbnail.png';
export { default as tag_welcome_thumbnail } from './tag-welcome-video-thumbnail.jpg';
export { default as tag_teammap_thumbnail } from './tag-teammap-video-thumbnail.jpg';
export { default as tag_sending_assessment_thumbnail } from './tag-sending-assessment-video-thumbnail.jpg';
export { default as tag_create_teammap_thumbnail } from './tag-create-teammap-video-thumbnail.jpg';
export { default as tag_6types_thumbnail } from './tag-6-types-video-thumbnail.jpg';
export { default as tag_hiring_thumbnail } from './tag-hiring-video-thumbnail.jpg';
export { default as tag_meetings_thumbnail } from './tag-meetings-video-thumbnail.jpg';
export { default as tag_share_results } from './tag-share-results.webp';
export { default as tag_missing_genius } from './missing-genius-illustration.webp';
export { default as team_application_guide_logo } from './team-application-guide-logo.png';
export { default as tag_logo_white } from './tag-logo-white.png';
export { default as team_app_guide_1 } from './team-application-guide-image-1.jpg';
export { default as application_guide_buy } from './application-guide-buy.png';
export const application_guide_step_gif =
	'https://files.tablegroup.com/wp-content/uploads/2024/05/09031313/application-guide.gif';
export { default as widget_list_icon } from './widget-list-icon.svg';
export { default as patric_and_application } from './patric_and_application_guide.png';
export { default as team_application_topics_gears } from './team-application-topics-gears.jpg';
export { default as team_application_topics_conversations } from './team-application-topics-conversations.jpg';
export { default as team_application_topics_profiles } from './team-application-topics-profiles.jpg';
export { default as team_application_notebook } from './team-application-guide-notebook.jpg';
export { default as pre_work_assessment } from './workshop-pre-work-assessment.jpg';
export { default as pre_work_10_minutes } from './workshop-pre-work-10-minutes.png';
export { default as pre_work_overview_thumbnail } from './workshop-pre-work-overview-thumbnail.jpg';
export { default as pre_work_categories_thumbnail } from './workshop-pre-work-categories-thumbnail.jpg';
export { default as pre_work_pat } from './workshop-pre-work-pat.png';
export { default as pre_work_results } from './pre-work-results.png';
export { default as workshop_widget_icon } from './workshop-widget-icon.png';
export { default as at_the_whiteboard_heading } from './at-the-whiteboard-heading.png';
export { default as widget_hr_primary } from './widget-hr-primary.png';
export { default as careers_network } from './careers-network.png';
export { default as workshop_precall_widget } from './workshop-precall-widget.png';
export { default as at_the_whiteboard_popup } from './at-the-whiteboard-popup.png';
export { default as at_the_whiteboard_live_placeholder } from './at-the-whiteboard-live-placeholder.png';
export { default as save_the_date } from './save-the-date.png';
export { default as using_admin_console_assessments } from './using-admin-console-assessments.jpg';
export { default as using_admin_console_manage_assessmennt } from './using-admin-console-manage.gif';
export { default as using_admin_console_request_assessment } from './using-admin-console-request-assessment.gif';
export { default as wg_gear } from './wg-gear.svg';
export { default as workshop_includes_icons } from './workshop-includes-icons.png';
export { default as workshop_broll } from './workshop-b-roll.webm';
export { default as workshop_logo } from './workshop-logo.png';
export { default as meetings_event_heading_white } from './event-november-8-heading-white.png';
export { default as ttg_wg_logos } from './ttg-wg-logos.png';
export { default as meetings_illustrative } from './meetings-illustrative.jpeg';
export { default as its_free_down_left_arrow } from './its-free-arrow-down-left.png';
export { default as new_pat_plain } from './new-pat-plain.jpg';
export { default as certification_broll } from './certification-broll.webm';
export { default as certification_mp4 } from './certification-mp4.mp4';
export { default as certified_badge } from './certified-badge.png';
export { default as certification_table_update } from './certification_table_update.png';
export { default as person_attending_certification } from './person-attending-certification.jpeg';
export { default as event_november_popup } from './event-november-popup.png';
export { default as podcast_at_the_table } from './podcast-at-the-table.png';
export { default as podcast_the_assessment } from './podcast-the-assessment.png';
export { default as podcast_book } from './podcast-book.png';
export { default as podcast_certification } from './podcast-certification.jpg';
export { default as podcast_tile_workshop } from './podcast-tile-workshop.png';
export { default as podcast_tile_assessment } from './podcast-tile-assessment.png';
export { default as podcast_tile_certification } from './podcast-tile-certification.png';
export { default as podcast_tile_audio } from './podcast-tile-audio.png';
export { default as tag_assessment_take } from './tag-take-assessment.png';
export { default as newly_released } from './newly-released.png';
export { default as wg_students_assessments } from './wg-students-assessment.png';
export { default as wg_students_logo } from './wg-students-logo.png';
export { default as student_assessment_icon } from './student-assessment.png';
export { default as student_fireworks_background } from './fireworks-background.gif';
export { default as student_email } from './student-email.png';
export { default as student_add } from './student-add.png';
export { default as widget_secondary } from './widget-secondary.png';
export { default as family_with_student } from './family-with-student.jpg';
export { default as student_group } from './student-group.jpg';
export { default as student_discussing } from './students-discussing.jpg';
export { default as teacher_and_student } from './teacher-and-student.jpg';
export { default as dca_wg_logo } from './dca-wg-logo-en.png';
export { default as student_discussion_guide } from './wg-student-dsicussion-guide.png';
export { default as for_students_sticker } from './for-students-sticker.png';
export { default as next_page_sticker } from './next-page-sticker.png';
export { default as gear_rays_icon } from './gear-rays-icon.svg';
export { default as student_pairings_description } from './student-pairings-description.png';
export { default as for_students_green_sticker } from './for-students-green-sticker.png';
export { default as dca_certification_logo } from './dca-certification-logo.png';
export { default as tag_store_image } from './tag-store-image.png';
export { default as certification_store_logo } from './certification-store-logo.png';
export { default as workshop_store_logo } from './workshop-store-logo.png';
export { default as webinars_stream_slide } from './webinar-stream-slide.png';
export { default as gear_w_updated } from './updated_gears/gear-w.svg';
export { default as gear_i_updated } from './updated_gears/gear-i.svg';
export { default as gear_d_updated } from './updated_gears/gear-d.svg';
export { default as gear_g_updated } from './updated_gears/gear-g.svg';
export { default as gear_e_updated } from './updated_gears/gear-e.svg';
export { default as gear_t_updated } from './updated_gears/gear-t.svg';
export { default as gear_p_updated } from './updated_gears/gear-p.svg';
export { default as gear_c_updated } from './updated_gears/gear-c.svg';
export { default as gear_f_updated } from './updated_gears/gear-f.svg';
export { default as gear_h_updated } from './updated_gears/gear-h.svg';
export { default as gear_m_updated } from './updated_gears/gear-m.svg';
export { default as gear_u_updated } from './updated_gears/gear-u.svg';
export { default as gear_b_updated } from './updated_gears/gear-b.svg';
export { default as student_report_qr } from './student-report-qr-code.png';
export const wg_summary_book_tablet =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/23095659/WG-Mobile-Tablet.webp';
export const new_ttg_logo_white = 'https://files.tablegroup.com/wp-content/uploads/2024/07/12085606/Powered-by-TG.webp';
export const request_graphic = 'https://files.tablegroup.com/wp-content/uploads/2024/07/14031712/Request-Graphic.webp';
export const wg_team_application_guide =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/14032327/Team-Application-Guide.webp';
export const individual_resources =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/14035307/Individual-Resources.webp';
export const event_podcast_new = 'https://files.tablegroup.com/wp-content/uploads/2024/07/14035250/Podcasts.webp';
export const team_resources = 'https://files.tablegroup.com/wp-content/uploads/2024/07/14035229/Team-Resources.webp';
export const book_best_seller_3d =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/14025253/WG-Best-Seller-Book.webp';
export const white_wg_logo =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/15031442/White-Working-Genius-Logotype.webp';
export const wg_meeting_pov_2 =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031559/Working-Genius-Intensives-Asset-6.webp';
export const wg_meeting_pov_1 =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031558/Working-Genius-Intensives-Asset-5.webp';
export const wg_grow_your_business =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031556/Working-Genius-Intensives-Asset-4.webp';
export const wg_tick =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031556/Working-Genius-Intensives-Asset-3.webp';
export const wg_flag =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031555/Working-Genius-Intensives-Asset-2.webp';
export const wg_alumni_intensives_header =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/29031554/Working-Genius-Intensives-Asset-1.webp';
export const wg_what_makes_us_different =
	'https://files.tablegroup.com/wp-content/uploads/2024/07/15115436/WG-Contrast-Graphic.webp';
export const certificationBadgeIcon =
	'https://files.tablegroup.com/wp-content/uploads/2024/11/04085326/0OP18hYu-39VHeF63.svg';

