import React from 'react'
import { getStoreStructuredData } from './StructuredMetaDataUtility';
import { Helmet } from 'react-helmet';

export const StoreStructuredMetaData = ({products}) => {
	const itemListData = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		"name": "The Working Genius Products",
		"description": "The Working Genius Products and Merchandise",
		"itemListElement": getStoreStructuredData(products),
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(itemListData)}
			</script>
		</Helmet>
	);
}