import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../components/Layout';
import { getQueryParams } from '../../services/UtilityService';
import { useUser } from '../../hooks/UserProvider';
import { useNotifications } from '../../hooks/NotificationProvider';

import { TTGRegister, withSSOAuthProvider } from '@ttg/ui-user-lib';
import { useSSOParams } from '../../hooks/useSSOParams';
import config from '../../constants/config';

const TTGRegisterWithProvider = withSSOAuthProvider(TTGRegister, useSSOParams);

const Register = () => {
	const { user, loadUser, } = useUser();
	const { loadNotifications } = useNotifications();
	const location = useLocation();
	const { token, redirect, certification_token, permission_token, license_token, type, email } = getQueryParams(location.search);
	const redirectDecoded = redirect ? decodeURIComponent(redirect) : undefined;
	const emailDecoded = email ? decodeURIComponent(email) : undefined;

	const handleSignupSuccess = async (_, onLoadFinish, redirectUrl) => {
		await loadUser();
		await loadNotifications();

		const currentRedirect = redirectDecoded ?? redirectUrl ?? '/client/';

		return navigate((currentRedirect).replace('//', '/'), { replace: true });
	};

	const tokenType = React.useMemo(() => {
		if (token) return config.AUTH_TOKENS.WG_ASSESSMENT;
		if (certification_token) return config.AUTH_TOKENS.WG_CERTIFICATION;
		if (permission_token) return config.AUTH_TOKENS.PERMISSION;
		if (license_token) return config.AUTH_TOKENS.WG_LICENSE;

		return undefined;
	}, [token, certification_token, permission_token, license_token]);

	useEffect(() => {
		if (user && user.id) {
			navigate('/client/', { replace: true });
		}
	}, []);

	const tokenValue = React.useMemo(() => token ?? certification_token ?? permission_token ?? license_token, [token, certification_token, permission_token, license_token])

	return (
		<Layout title={'Register | The 6 Types Of Working Genius'} style={{ background: '#F5F5F5' }}>
			<div className='mx-auto mt-12'>
				<TTGRegisterWithProvider
					captchaEnabled={config.captcha_enabled}
					captchaKey={config.captcha_site_key}
					apiEndpoint={config.ttg_url}
					onFinish={handleSignupSuccess}
					token={tokenValue}
					tokenType={tokenType}
					resource={type || redirectDecoded ? {
						email: emailDecoded,
						type,
						hasTakenWgAssessment: false,
						wgResults: "",
						redirectPath: redirectDecoded
					} : undefined}
				/>
			</div>
		</Layout>
	);
};

export default Register;
