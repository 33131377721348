import ApiService from './ApiService';
import { formatPrice } from './UtilityService';
import dayjs from 'dayjs';

const groupCertificationDates = (dates) => {
	const available = [],
		soldOut = [];
	dates.map((date) => {
		if (date.remaining_spots > 0) {
			available.push(date);
		} else {
			soldOut.push(date);
		}
	});
	return { available, soldOut };
};

export const getCertificationDates = (data) => {
	const currentTime = dayjs(),
			dateFormat = 'MM-DD-YYYY';
	const allCerts = data
			?.filter((product) => product.metadata.private === 'false' && dayjs(product.metadata?.resources_access_date, dateFormat).isAfter(currentTime))
			.map((product) => ({
				certification_product_id: product.id,
				product_key: 'certification_product_id',
				name: 'Working Genius Certification',
				start_date: product.metadata.start_date,
				end_date: product.metadata.end_date,
				event_time: product.metadata.event_time,
				new_session: product.metadata.new_session === 'true',
				is_weekend: product.metadata.is_weekend === 'true',
				defaultPrice: product.defaultPrice.unit_amount / 100,
				price: formatPrice(product.defaultPrice.unit_amount / 100),
				remaining_spots: Number(product.metadata.stock),
				resources_access_date: product.metadata.resources_access_date,
				variation_id: product.metadata.variation_id
			}))
			.sort((a, b) =>
					dayjs(a.resources_access_date, dateFormat).diff(
							dayjs(b.resources_access_date, dateFormat)
					)
			);

	return ({
		available: allCerts.filter((product) => product.remaining_spots > 0),
		soldOut: allCerts.filter(
				(product) =>
						dayjs(product.resources_access_date, dateFormat).isAfter(currentTime) &&
						product.remaining_spots < 1
		),
	});
};

export const getAlumniIntensiveDates = (data) => {
	const dateFormat = 'MM-DD-YYYY';
	const allAlumniIntensives = data
			?.filter((product) => product.metadata.private === 'false')
			.map((product) => ({
				alumni_intensives_product_id: product.id,
				product_key: 'alumni_intensives_product_id',
				name: product?.name?.split(/\s*-\s*/)?.pop() || product.name,
				start_date: product.metadata.start_date,
				end_date: product.metadata.end_date,
				event_time: product.metadata.event_time,
				new_session: product.metadata.new_session === 'true',
				is_weekend: product.metadata.is_weekend === 'true',
				defaultPrice: product.defaultPrice.unit_amount / 100,
				price: formatPrice(product.defaultPrice.unit_amount / 100),
				remaining_spots: Number(product.metadata.stock),
				expected_resources_at: product.metadata.expected_resources_at,
				variation_id: product.metadata.variation_id
			}))
			.filter(item => item?.remaining_spots > 0)
			.sort((a, b) =>
					dayjs(a.expected_resources_at, dateFormat).diff(
							dayjs(b.expected_resources_at, dateFormat)
					)
			);
	return ({
		available: allAlumniIntensives?.filter((product) => product.remaining_spots > 0),
	});
};

export const getPreworkItems = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/prework_items`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const completePreWorkItem = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/prework_items/${id}`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const getCertificationResources = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/certification_resources`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getResourceDetails = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/certification_resources/${id}`)
			.then(({ data }) => {
				resolve(data.contents);
			})
			.catch(reject);
	});
};

export default {
	groupCertificationDates,
	getCertificationDates,
	getPreworkItems,
	completePreWorkItem,
	getCertificationResources,
	getResourceDetails,
	getAlumniIntensiveDates
};
