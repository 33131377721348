import React, { useEffect, useMemo } from 'react';
import {useCheckout} from "@ttg/ui-checkout-lib";

import WorkshopLayout from '../../../components/workshop/WorkshopLayout';
import HeroSection from '../../../components/toolkit/product/HeroSection';
import StepGuide from '../../../components/toolkit/product/StepGuide';
import Buy from '../../../components/toolkit/product/Buy';
import Topics from '../../../components/toolkit/product/Topics';
import LastBuy from '../../../components/toolkit/product/LastBuy';
import Quote from '../../../components/toolkit/product/Quote';
import config from '../../../constants/config';
import CustomerService from '../../../services/CustomerService';
import { ProductStructuredMetaData } from '../../../components/store/ProductStructuredMetaData';

const Product = () => {
	const {customHooks: {productHook: {products}}} = useCheckout();
	const item = useMemo(() => products?.find(product => product.id === config.TAG_stripe_product_id), [products]);

	useEffect(() => {
		if(item){
			CustomerService.triggerGoogleTagManagerEvent({
				value: (item?.defaultPrice?.unit_amount || 0) / 100,
				products: [item],
			})
		}
	}, [item]);

	return (
		<WorkshopLayout
			title={'Team Application Guide | The 6 Types of Working Genius'}
			description={'Your digital toolkit for team adoption and impact of The 6 Types of Working Genius'}
		>
			<div id={'wg-team-app-guide'}>
				{item && <ProductStructuredMetaData product={item} />}
				<HeroSection />
				<StepGuide />
				<Buy />
				<Topics />
				<LastBuy />
				<Quote />
			</div>
		</WorkshopLayout>
	);
};

export default Product;
