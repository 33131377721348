import React, { useMemo } from 'react';
import AssessmentAboutBuy from '../../../components/about/assessment/AssessmentAboutBuy';
import AssessmentAboutBuyNow from '../../../components/about/assessment/AssessmentAboutBuyNow';
import AssessmentAboutResults from '../../../components/about/assessment/AssessmentAboutResults';
import AssessmentStudent from '../../../components/about/assessment/AssessmentStudent';
import AssessmentAboutFAQ from '../../../components/about/assessment/AssessmentAboutFAQ';
import config from '../../../constants/config';
import AssessmentAboutDifference from '../../../components/about/assessment/AssessmentAboutDifference';
import { ProductStructuredMetaData } from '../../../components/store/ProductStructuredMetaData';
import { useCheckout } from "@ttg/ui-checkout-lib";

const AssessmentAbout = () => {
	const {customHooks: {productHook: {products}}} = useCheckout();
	const item = useMemo(() => products?.find(product => product.id === config.wg_assessment_stripe_product_id), [products]);

	return (
		<>
			{item && <ProductStructuredMetaData product={item} />}
			<AssessmentAboutBuy />
			<AssessmentAboutBuyNow />
			<AssessmentAboutDifference />
			<AssessmentAboutResults />
			<AssessmentStudent />
			<AssessmentAboutFAQ />
		</>
	);
};

export default AssessmentAbout;
