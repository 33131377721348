import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import AppButton from '../../../components/AppButton';
import Error from '../../../components/assessments/Error';
import Layout from '../../../components/Layout';
import ReCaptcha from '../../../components/ReCaptcha';

import { useUser } from '../../../hooks/UserProvider';
import { getQueryParams } from '../../../services/UtilityService';
import PermissionsService from '../../../services/PermissionsService';
import CustomerService from '../../../services/CustomerService';
import NotificationService from '../../../services/NotificationService';

const ConfirmPermissionRequest = () => {
	const [loadingResponse, setLoadingResponse] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [success, setSuccess] = React.useState(false);

	const location = useLocation();
	const { type, token = '' } = getQueryParams(decodeURI(location.search));
	const captchaRef = React.useRef('captcha');
	const { loadUser, user } = useUser();

	const handleVerify = async (accepted) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoadingResponse(true);
		PermissionsService.verifyPermission({
			token: token,
			status: accepted ? 'APPROVED' : 'DENIED',
			captcha: recaptchaValue,
		})
			.then(() => {
				setSuccess(true);
				if(user) {
					CustomerService.refreshUser()
							.then(() => {
								loadUser();
							})
							.catch((error) => {
								NotificationService.error('Oops', 'Something went wrong, please try again');
							});
				}
			})
			.catch((e) => {
				setError(e.message);
			})
			.finally(() => {
				setLoadingResponse(false);
			});
	};

	const renderHeader = (type) => {
		if (type === 'accept')
			return (
				<div>
					<h1 className={'text-xl text-primary font-semibold mb-6'}>
						You have been invited to access another person's account.
					</h1>
					<p className={'text-primary mb-6'}>Would you like to accept this invitation?</p>
				</div>
			);

		if (type === 'decline')
			return (
				<div>
					<h1 className={'text-xl text-primary font-semibold mb-6'}>
						You have been invited to access another person's account.
					</h1>
					<p className={'text-primary mb-6'}>Would you like to decline this invitation?</p>
				</div>
			);
	};

	const renderContent = () => {
		if (loading) {
			return (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			);
		}

		if (success)
			return (
				<div className={'flex flex-col items-center container mt-8'}>
					<p className={'text-xl md:text-lg text-primary mb-6'}>Thank you for your response.</p>
					<AppButton
						text={'Go to Account'}
						onClick={() => navigate('/client')}
						className={'btn btn-primary w-56 mt-4'}
					/>
				</div>
			);

		if (error) return <Error message={error} />;

		return (
			<div className={'mt-8 text-center'}>
				{renderHeader(type)}
				<div className='flex items-center justify-center'>
					{type === 'accept' && (
						<AppButton
							onClick={() => handleVerify(true)}
							className={'btn btn-primary'}
							text={'Accept'}
							id={'wg-request-share-allow-btn'}
							disabled={loadingResponse}
						/>
					)}
					{type === 'decline' && (
						<AppButton
							onClick={() => handleVerify(false)}
							className={'btn btn-primary ml-2'}
							text={'Decline'}
							disabled={loadingResponse}
						/>
					)}
					<ReCaptcha ref={captchaRef} />
				</div>
			</div>
		);
	};

	return <Layout>{renderContent()}</Layout>;
};

export default ConfirmPermissionRequest;
