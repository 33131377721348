import React from 'react';
import { navigate } from 'gatsby';

import AppCopyToClipboard from '../../AppCopyToClipboard';
import AppCopyToClipboardMobile from '../../AppCopyToClipboardMobile';
import AppButton from '../../AppButton';
import { useAssessments } from '../../../hooks/AssessmentProvider';

const texts = {
	group: '<p class="text-primary text-2xl"><span class="font-bold">2. Email the link</span> to your group/team for them to take the assessment.</p>',
	individual:
		'<p class="text-primary text-2xl"><span class="font-bold">2. Email the link</span> to your participant for them to take the assessment.</p>',
};

const ConfirmationStep = ({shareReport}) => {
	const { assessment: createdAssessment } = useAssessments();

	return (
		<div className={'container px-4 md:px-0 md:mx-auto mt-8'}>
			<h1 className='font-bold text-5xl text-primary mb-4'>Important Next Steps</h1>
			<p className={'text-primary text-2xl font-bold'}>1. Copy and paste the following link:</p>
			<h1 className='text-error text-xl font-bold ml-4 mb-6 hidden md:block'>
				<AppCopyToClipboard
					value={`https://${window.location.hostname}/assessment/${createdAssessment.slug}`}
					id={'wg-generated-link-id'}
				/>
			</h1>
			<div className={'w-full my-2 md:hidden'}>
				<AppCopyToClipboardMobile
					text={'COPY ASSESSMENT LINK'}
					value={`https://${window.location.hostname}/assessment/${createdAssessment.slug}`}
					id={'wg-generated-link-id-mobile'}
				/>
			</div>
			<div className={'mb-6'} dangerouslySetInnerHTML={{ __html: texts[createdAssessment.type] }} />
			<p className={'text-primary text-2xl mb-6'}>
				<span className='font-bold'>3. Check your console</span> for updates and to view/download completed
				reports. { !shareReport && 'All reports are also sent to the participants.'}
			</p>
			<div className='flex justify-end'>
				<AppButton text={'Done'} onClick={() => navigate('/client/')} id={'wg-setup-assessment-done-btn'} />
			</div>
		</div>
	);
};

export default ConfirmationStep;
