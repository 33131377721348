const { AUTH_PROVIDERS, AUTH_TOKENS } = require('@ttg/app-interfaces-lib');

const env = {
	unit_test: {
		name: 'unit_test',
		api_url: 'http://host.docker.internal:8001/api',
		base_url: 'http://host.docker.internal:3001',
		ttg_url: 'http://host.docker.internal:7070',
		captcha_site_key: '6Lec7_oiAAAAAAD_W9rnuHpvuIzSQZNcAkJZN60L',
		captcha_enabled: false,
		ac_certification_subscription_link: 'https://ritech1619167525.activehosted.com/f/embed.php?id=1',
		wg_assessment_stripe_product_id: 'prod_O06fnV4X3kK2CU',
		wg_student_assessment_stripe_product_id: 'prod_P479TCOlC9pmnn',
		certification_stripe_product_id: 'prod_PLOJernh18SIAf',
		wg_book_stripe_product_id: 'prod_Q0sJRJh30o8KZQ',
		commerce_api_path: '/api-ttg-commerce/',
		stripe_p_key:
			'pk_test_51HZvkcC8RRIq0qn5ELqbQeYJqgrekpCU5wFXay8L6gr0Ai8zqaiKa4dZH4505H064nXVyjaHbnZBJm8hOEKveLrC00AJhDNErs',
		alumni_intensive_feature: false,
		sm_api: 'http://host.docker.internal:3005',
		googleClientId: "737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com",
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		microsoftClientId: '1fe6075f-6a2e-42e0-8ffe-76cb6bf0094f',
		linkedinClientId: '77orzyhfljjl3x',
		AUTH_TOKENS,
	},
	dev: {
		name: 'dev',
		sentry_dns: 'https://5f87812b096044f7a50de15e4086657a@o876561.ingest.sentry.io/5975311',
		api_url: 'https://api.dev.theworkinggenius.link/api',
		base_url: 'https://dev.theworkinggenius.link',
		ttg_url: 'https://app.dev.thetablegroup.link',
		captcha_site_key: '6Lec7_oiAAAAAAD_W9rnuHpvuIzSQZNcAkJZN60L',
		captcha_enabled: true,
		ac_certification_subscription_link: 'https://ritech1619167525.activehosted.com/f/embed.php?id=1',
		wg_assessment_stripe_product_id: 'prod_O06fnV4X3kK2CU',
		wg_student_assessment_stripe_product_id: 'prod_P479TCOlC9pmnn',
		TAG_stripe_product_id: 'prod_O06bua2r9lnmGa',
		certification_stripe_product_id: 'prod_PLOJernh18SIAf',
		alumni_intensives_stripe_product_id: 'prod_PLOJernh18SIAf',
		wg_book_stripe_product_id: 'prod_Q0sJRJh30o8KZQ',
		commerce_api_path: '/api-ttg-commerce/',
		stripe_p_key:
			'pk_test_51HZvkcC8RRIq0qn5ELqbQeYJqgrekpCU5wFXay8L6gr0Ai8zqaiKa4dZH4505H064nXVyjaHbnZBJm8hOEKveLrC00AJhDNErs',
		alumni_intensive_feature: true,
		sm_api: 'https://dev.thestaffmatcher.link',
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		googleClientId: "737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com",
		microsoftClientId: '1fe6075f-6a2e-42e0-8ffe-76cb6bf0094f',
		linkedinClientId: '77orzyhfljjl3x',
		AUTH_TOKENS,
	},
	stage: {
		name: 'stage',
		sentry_dns: 'https://5f87812b096044f7a50de15e4086657a@o876561.ingest.sentry.io/5975311',
		api_url: 'https://api.stage.theworkinggenius.link/api',
		base_url: 'https://stage.theworkinggenius.link',
		ttg_url: 'https://app.stage.thetablegroup.link',
		captcha_site_key: '6Lec7_oiAAAAAAD_W9rnuHpvuIzSQZNcAkJZN60L',
		captcha_enabled: true,
		ac_certification_subscription_link: 'https://ritech1619167525.activehosted.com/f/embed.php?id=1',
		wg_assessment_stripe_product_id: 'prod_ObPHPeqXHCuxDK',
		wg_student_assessment_stripe_product_id: 'prod_P479TCOlC9pmnn',
		certification_stripe_product_id: 'prod_POkwqBqgu2QMto',
		alumni_intensives_stripe_product_id: 'prod_PLOJernh18SIAf',
		wg_book_stripe_product_id: 'prod_OgBNVYNBZ5WoD8',
		commerce_api_path: '/api-ttg-commerce/',
		stripe_p_key:
			'pk_test_51ILZJeAbfiSdI67WW7YYcLsOuUqK2aj5QoXVyRXZnULucLY09GjM796rQ3Nx81QFGl93vgCZOsNZJ7u44Ys98FVK00EQCDyc4w',
		TAG_stripe_product_id: 'prod_ObPMwbV6PdJKMu',
		alumni_intensive_feature: true,
		sm_api: 'https://stage.thestaffmatcher.link',
		googleClientId: "737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com",
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		microsoftClientId: '624eee06-ff8f-45d9-8eec-490b4bc89554',
		linkedinClientId: '86cukfsxlehx55',
		AUTH_TOKENS,
	},
	prod: {
		name: 'prod',
		sentry_dns: 'https://5f87812b096044f7a50de15e4086657a@o876561.ingest.sentry.io/5975311',
		api_url: 'https://api.workinggenius.com/api',
		base_url: 'https://www.workinggenius.com',
		ttg_url: 'https://www.tablegroup.com',
		captcha_site_key: '6LeBOGAjAAAAADAW9muwc7Dlja3vtsO8Ut7pUiwS',
		captcha_enabled: true,
		ac_certification_subscription_link: 'https://tablegroup.activehosted.com/f/embed.php?id=3',
		wg_assessment_stripe_product_id: 'prod_OlPe1hkDmR6l54',
		wg_student_assessment_stripe_product_id: 'prod_P479TCOlC9pmnn',
		certification_stripe_product_id: 'prod_PTzV2jkchAFolO',
		alumni_intensives_stripe_product_id: 'prod_QiOgl0zHMWcPIF',
		wg_book_stripe_product_id: 'prod_OlPeriSfovzwCO',
		commerce_api_path: '/api-ttg-commerce/',
		stripe_p_key:
			'pk_live_51Ie5UXEsXha49zzY2qxxbRKEFPirb7J3dC3Uq6mqP4S6MOnDzUGZ7paCPJvWlAFpgNW3on7KoLEvmwm5a2wLdmks00IR09jlUK',
		TAG_stripe_product_id: 'prod_OlPe4q9rmJgAqF',
		alumni_intensive_feature: true,
		sm_api: 'https://workinggenius.ai',
		googleClientId: "737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com",
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		microsoftClientId: '624eee06-ff8f-45d9-8eec-490b4bc89554',
		linkedinClientId: '86cukfsxlehx55',
		AUTH_TOKENS,
	},
};

const config = env[process.env.GATSBY_REACT_APP_ENV || 'dev'] || env.dev;
export default config;
