import ApiService from './ApiService';
import UtilityService, { setAuthCookie } from './UtilityService';
import config from '../constants/config';
import { ReadableUserRoles, USER_ROLE } from "@ttg/app-interfaces-lib";

const mapCustomer = {
	id: 'customers_id',
	first_name: 'customers_firstname',
	last_name: 'customers_lastname',
	licenses: 'wg_unassigned_licenses',
	wg_certification_status: 'wg_certification_status',
	customers_authorization: 'customers_authorization',
	customers_default_address_id: 'customers_default_address_id',
	customers_email_address: 'customers_email_address',
	customers_referral: 'customers_referral',
	default_email_format: 'default_email_format',
	default_timezone: 'default_timezone',
	customers_telephone: 'customers_telephone',
	company: 'company',
	email_change_request: 'email_change_request',
	multi_lingual: 'multi_lingual',
	is_wg_trainee: 'is_wg_trainee',
	show_team_application_guide: 'show_team_application_guide',
	wg_student_enabled: 'wg_student_enabled',
	stripe_customer_id: 'stripe_customer_id',
	agree: 'agree',
	working_genius_token: 'working_genius_token',
	role: 'customers_role',
	allow_po: 'allow_po',
	has_permissions_enabled: 'customers_permissions_enabled',
	customers_company_size: 'customers_company_size',
	customers_industry: 'customers_industry',
	has_other_accounts: 'has_other_accounts',
	wg_alumni_intensives: 'wg_alumni_intensives'
};

const prepareCustomer = (customer) => {
	const data = {};
	if (!customer) {
		return data;
	}
	Object.keys(mapCustomer).forEach((key) => {
		data[key] = customer[mapCustomer[key]];
	});
	data.fullName = `${data.first_name} ${data.last_name}`;
	data.hasPurchasedCertification = data.wg_certification_status && data.wg_certification_status.status_id > 0;
	data.hasPurchasedCertification = data.wg_certification_status && data.wg_certification_status.status_id > 0;
	data.hasCompletedPreWork = data.wg_certification_status && data.wg_certification_status.status_id > 3;
	data.hasPurchasedAlumniIntensives = data?.wg_alumni_intensives && data.wg_alumni_intensives?.length > 0;
	window.ttg_timezone = customer.default_timezone;
	data.original_customer = customer.original_customer || null;
	data.customers_login_method = customer.customers_login_method;
	return data;
};

export const login = ({ email, password, type }) =>
	new Promise((resolve, reject) => {
		const payload = type
			? { email, password, type, has_taken_wg_assessment: 'No', working_genius_results: '' }
			: { email, password };
		ApiService.post('v1/login', payload)
			.then((response) => {
				const { token, customer } = response.data;
				setAuthCookie(token);

				resolve(prepareCustomer(customer));
			})
			.catch((error) => {
				setAuthCookie('');
				reject(error);
			});
	});

export const getCustomerData = () =>
	new Promise((resolve, reject) => {
		const authCookie = UtilityService.getAuthCookie();

		if (!authCookie) {
			return reject();
		}

		ApiService.get('v1/customer', {
			headers: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
			},
		})
			.then((response) => resolve(prepareCustomer(response.data)))
			.catch((error) => {
				reject(error);
			});
	});

export const switchUser = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/switch`, { user_id: id })
			.then((response) => {
				const { token, fresh_user } = response.data;
				setAuthCookie(token);
				resolve(prepareCustomer(fresh_user));
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const switchToOriginalUser = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/switch_back`)
			.then((response) => {
				const { token, customer } = response.data;
				setAuthCookie(token);
				resolve(prepareCustomer(customer));
			})
			.catch((error) => {
				setAuthCookie('');
				reject(error);
			});
	});
};

export const refreshUser = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/refresh`, {})
			.then((response) => {
				const { token, customer } = response.data;
				resolve(prepareCustomer(customer));
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const logout = (id) => {
	window.logoutClick(id);
	return ApiService.post(`v1/logout`);
};

export const update = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/customer`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const addToCart = async (key, id, variation_id) => {
	if (window.gtag) {
		window.gtag('event', 'conversion', { send_to: 'AW-1042091124/AhoiCMG68JEYEPSY9PAD' });
	}

	const productIdsMappings = {
		certification_product_id: id,
		team_application_product_id: config.TAG_stripe_product_id,
		add_license: config.wg_assessment_stripe_product_id,
		add_student_license: config.wg_student_assessment_stripe_product_id,
		add_wg_book: config.wg_book_stripe_product_id,
		other: id,
		alumni_intensives_product_id: id
	};
	window.addItemToCart(productIdsMappings?.[key] || config.wg_assessment_stripe_product_id, variation_id);
};

export const forgotPassword = ({ email, captcha }) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/forgot_password`, { email, captcha }).then(resolve, reject);
	});
};

export const resetPassword = ({ token, password, new_password }) =>
	new Promise((resolve, reject) => {
		ApiService.post(`v1/user/reset_password`, { token, password, new_password })
			.then((response) => {
				const { token, customer } = response.data;
				setAuthCookie(token);
				resolve(prepareCustomer(customer));
			})
			.catch((error) => {
				reject(error);
			});
	});

export const updatePassword = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/update_password`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const verifyLink = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/user/verify_reset_password_token?token=${token}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestEmailChange = (new_email) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/request_email_change`, { new_email })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const undoEmailChange = () => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/undo_email_change`)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const verifyChangeToken = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/verify_email_change`, { token })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const invite = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/send_account_invite`, payload)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const verifyInvite = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/user/verify_account_invite?token=${token}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const register = (payload) =>
	new Promise((resolve, reject) => {
		ApiService.post(`v1/user/register`, payload)
			.then((response) => {
				const { token, customer } = response.data;
				setAuthCookie(token);
				resolve(prepareCustomer(customer));
			})
			.catch((error) => {
				reject(error);
			});
	});

const verifyEmail = (email, captcha = null) => {
	return ApiService.post(`v1/user/verify_user_email`, { email, captcha }).then((response) => {
		const { exists } = response.data;

		return exists;
	});
};

const triggerGoogleTagManagerEvent = (data, event = 'view_item') => {
	if (!window.dataLayer) return;

	const eventMappings = {
		view_item: {
			ecommerce: {
				currency: 'USD',
				value: data.value,
				items: data?.products?.map((product) => ({
					item_id: product?.id || '',
					item_name: product?.name || '',
					item_category: product?.metadata?.category || 'Product',
					price: data.value,
					quantity: product?.quantity || 1,
				})),
			},
		},
		generate_lead: {
			generate_lead: {
				currency: 'USD',
				...data,
			},
		},
	};
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: event,
		...eventMappings[event],
	});
};
const CustomerBusinessRoles = Object.entries(ReadableUserRoles).map(([value, label]) => ({
	value,
	label,
}));

const USER_ROLE_ENUM = USER_ROLE;

const CustomerCompanySizes = [
	{ label: '0 - 50 Employees', value: '0-50_EMPLOYEES' },
	{ label: '51 - 500 Employees', value: '51-500_EMPLOYEES' },
	{ label: '501 - 1,000 Employees', value: '501-1000_EMPLOYEES' },
	{ label: '1,001 - 2,500 Employees', value: '1001-2500_EMPLOYEES' },
	{ label: '2,501 - 5,000 Employees', value: '2501-5000_EMPLOYEES' },
	{ label: '5,001 - 10,000 Employees', value: '5001-10000_EMPLOYEES' },
	{ label: '10,001+ Employees', value: '10001_EMPLOYEES' },
];

const CustomerIndustries = [
	{ label: 'Advertising/Marketing', value: 'ADVERTISING_MARKETING' },
	{ label: 'Aerospace', value: 'AEROSPACE' },
	{ label: 'Agriculture', value: 'AGRICULTURE' },
	{ label: 'Automotive', value: 'AUTOMOTIVE' },
	{ label: 'Computer/Technology', value: 'COMPUTER_TECHNOLOGY' },
	{ label: 'Construction/Engineering', value: 'CONSTRUCTION_ENGINEERING' },
	{ label: 'Consulting/Coaching', value: 'CONSULTING_COACHING' },
	{ label: 'Education - Post-Secondary', value: 'EDUCATION_POST_SECONDARY' },
	{ label: 'Education - Primary/Secondary', value: 'EDUCATION_PRIMARY_SECONDARY' },
	{ label: 'Energy', value: 'ENERGY' },
	{ label: 'Entertainment', value: 'ENTERTAINMENT' },
	{ label: 'Fashion', value: 'FASHION' },
	{ label: 'Finance', value: 'FINANCE' },
	{ label: 'Fitness/Nutrition', value: 'FITNESS_NUTRITION' },
	{ label: 'Food/Beverage', value: 'FOOD_BEVERAGE' },
	{ label: 'Government', value: 'GOVERNMENT' },
	{ label: 'Healthcare', value: 'HEALTHCARE_NON_HOSPITAL' },
	{ label: 'Hospitality', value: 'HOSPITALITY' },
	{ label: 'Hospitals', value: 'HOSPITALS' },
	{ label: 'Insurance', value: 'INSURANCE' },
	{ label: 'Law', value: 'LAW' },
	{ label: 'Manufacturing', value: 'MANUFACTURING' },
	{ label: 'Media/News', value: 'MEDIA_NEWS' },
	{ label: 'Mining', value: 'MINING' },
	{ label: 'Ministry/Churches', value: 'MINISTRY_CHURCHES' },
	{ label: 'Non-Profit Organizations', value: 'NON_PROFIT' },
	{ label: 'Other', value: 'OTHER' },
	{ label: 'Pharma/Biotechnology', value: 'PHARMA_BIOTECHNOLOGY' },
	{ label: 'Professional Services', value: 'PROFESSIONAL_SERVICES' },
	{ label: 'Real Estate', value: 'REAL_ESTATE' },
	{ label: 'Retail', value: 'RETAIL' },
	{ label: 'Staffing', value: 'STAFFING' },
	{ label: 'Student', value: 'STUDENT' },
	{ label: 'Telecommunications', value: 'TELECOMMUNICATIONS' },
	{ label: 'Transportation', value: 'TRANSPORTATION' },
];

const WorkshopTeamSize = [
	{
		value: '3-5_PEOPLE',
		label: '3-5 People',
	},
	{
		value: '5-10_PEOPLE',
		label: '5-10 People',
	},
	{
		value: '10-15_PEOPLE',
		label: '10-15 People',
	},
];

export default {
	mapCustomer,
	login,
	prepareCustomer,
	getCustomerData,
	logout,
	refreshUser,
	update,
	switchUser,
	switchToOriginalUser,
	addToCart,
	forgotPassword,
	resetPassword,
	updatePassword,
	verifyLink,
	requestEmailChange,
	undoEmailChange,
	verifyChangeToken,
	invite,
	verifyInvite,
	register,
	verifyEmail,
	triggerGoogleTagManagerEvent,
	CustomerBusinessRoles,
	USER_ROLE_ENUM,
	CustomerIndustries,
	CustomerCompanySizes,
	WorkshopTeamSize,
};
