import React from 'react';
import AppIcon from '../AppIcon';

const styles = {
	success: {
		color: 'success',
		icon: 'ok-circled2',
	},
	error: {
		color: 'error',
		icon: 'warning-empty',
	},
	info: {
		color: 'secondary',
		icon: 'info-circled',
	},
};

const ToasterIcon = ({ type = 'success', customIcon }) => {
	return (
		customIcon ?
			<img src={customIcon} alt={''} className='self-start mt-1'/> :
			<AppIcon
				icon={customIcon ? customIcon : styles[type].icon}
				className={`text-${styles[type].color} text-center`}
				style={{ fontSize: '16px' }}
			/>
	);
};

export default ToasterIcon;
