import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
		maxHeight: '85vh',
		maxWidth: '43rem',
	},
};

const EmailList = ({ title, emails }) => {
	const [visibleCount, setVisibleCount] = React.useState(6);
	const displayedEmails = emails.slice(0, visibleCount);

	return (
		<div className='flex flex-col justify-around mb-6'>
			<p>{title}</p>

			<div className='wg-bulk-share-emails-list bulk-duplicate'>
				<ul>
					{displayedEmails.map((email, index) => (
						<li key={`${email}-${index}`} className='p-1 text-gray-700 ml-6'>
							{email}
						</li>
					))}
				</ul>

				{visibleCount < emails.length && (
					<button
						className='text-medium text-secondary cursor-pointer py-2'
						onClick={() => setVisibleCount((prevCount) => prevCount + 6)}
					>
						Load More
					</button>
				)}
			</div>
		</div>
	);
};

const BulkDuplicateDetails = ({ onClose, intro, alreadyDuplicatedAssessments = [], rosters, onDuplicate, loading }) => {
	const alreadyDuplicatedRosters = rosters?.filter(({ roster_id }) => alreadyDuplicatedAssessments?.includes(roster_id));
	const toBeDuplicatedRosters = rosters?.filter(({ roster_id }) => !alreadyDuplicatedAssessments?.includes(roster_id));

	return (
		<AppModal
			id='wg-bulk-duplicate-modal-id'
			isOpen={!!alreadyDuplicatedAssessments}
			style={customStyles}
			onRequestClose={onClose}
			closeTimeout={0}
		>
			<div className='bg-white p-6 text-sm font-normal text-gray-600'>
				<h1 className='flex text-primary text-lg leading-6 font-medium'>Duplicate Assessments</h1>
				<p className='pb-8' dangerouslySetInnerHTML={{ __html: intro }} />

				{alreadyDuplicatedAssessments?.length > 0 && (
					<EmailList
						title='These assessments are already duplicated:'
						emails={alreadyDuplicatedRosters.map((roster) => roster.email)}
					/>
				)}

				{toBeDuplicatedRosters.length > 0 && (
					<EmailList
						title='The following assessments will be duplicated:'
						emails={toBeDuplicatedRosters.map((roster) => roster.email)}
					/>
				)}

				<div className='flex flex-col sm:flex-row mt-4 justify-end'>
					<AppButton onClick={onClose} text='Cancel' className='btn btn-secondary my-2 sm:mr-4' />

					{toBeDuplicatedRosters.length > 0 && (
						<AppButton
							onClick={onDuplicate}
							disabled={loading}
							text={'Duplicate Assessments'}
							className='btn btn-primary my-2'
							loading={loading}
						/>
					)}
				</div>
			</div>
		</AppModal>
	);
};

export default BulkDuplicateDetails;
