import React from 'react';
import EventItem from './EventItem';
import { cert_notification } from '../../../images';
import AppButton from '../../AppButton';
import AppText from '../../AppText';
import DcaCard from './DcaCard';

const Events = ({ title, events, onRegister, onNotify, loading, onRegisterAsAdmin, isAdmin }) => {
	if (loading) {
		return (
			<div id={'events-loading-spinner'} className='relative'>
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={'flex flex-1 flex-col justify-center my-5'}>
			<h2 className={'text-center text-primary text-4xl font-thin mb-4'}>{title}</h2>
			<div className='container mx-auto'>
				<div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8'}>
					{events.map((event, key) => {
						if (event.notify) {
							return (
								<div
									id={'events-notify-item'}
									className={'flex flex-col bg-white shadow-md p-4 h-100'}
									key={key}
								>
									<div className={'flex flex-1 flex-col'}>
										<img className={'w-20 h-20 mx-auto my-4'} src={cert_notification} alt='' />
										<AppText
											className='text-center'
											text={
												"Don't see a time that works for you? Get notified when future sessions are available."
											}
										/>
									</div>
									<div className={'mt-5 w-1/2 mx-auto'}>
										<AppButton
											className={'btn btn-secondary w-full'}
											text={'get notified'}
											onClick={onNotify}
											id={'wg-events-get-notified-btn'}
										/>
									</div>
								</div>
							);
						}
						if (event.spanish) {
							return <DcaCard key={key} language={'es'} certificationLink={'https://www.dalecarnegie.com/es/courses/6578'} />
						}
						if (event.german) {
							return <DcaCard key={key} language={'de'} certificationLink={'https://www.dalecarnegie.com/de/courses/6578'} />
						}
						return <EventItem key={key} event={event} onRegister={onRegister} onRegisterAsAdmin={onRegisterAsAdmin} isAdmin={isAdmin}/>;
					})}
				</div>
			</div>
		</div>
	);
};

export default Events;
