import React, {useEffect} from 'react';
import {useLocation, useNavigate} from '@reach/router';

const Wizard = () => {
    const location = useLocation();
	const navigate = useNavigate();


	useEffect(() => {
		navigate(`/client/register/${location.search}`)
	}, [navigate])


	return null;
};

export default Wizard;
